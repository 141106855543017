export default () =>
{
    apos.util.onReady( () =>
    {
        apos.util.runPlayers();
    } );

    apos.util.widgetPlayers.travelDesignerFloating = {
        selector: '[id=floating-contact-container]',
        player: function( el )
        {
            const toggleFloatingContactButtons = document.querySelectorAll( '[data-toggle-floating]' );
            toggleFloatingContactButtons.forEach( function( button )
            {
                button.addEventListener( 'click', function( event )
                {
                    document.getElementById( 'floating-contact-container' ).classList.toggle( 'floating-contact-open' );
                } );
            } );
        }
    };
};
