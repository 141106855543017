import {
    moveSearchInputBackToMenu,
    toggleClass
} from '../index';

const windowFunctions = {
    togglePopup: togglePopup,
    setDestinationPreference: setDestinationPreference,
    movePopupBackToOriginalParent: movePopupBackToOriginalParent,
    closeActivePopupWithMask: closeActivePopupWithMask,
    closeCustomSelectPopup: closeCustomSelectPopup,
    movePopupToLayout: movePopupToLayout,
    removePopup: removePopup,
    openPopup: openPopup,
    closePopup: closePopup
};
Object.assign( window, windowFunctions );

export function setDestinationPreference( selectedDestination )
{
    let currentProductInfo = JSON.parse( sessionStorage.getItem( "dw_cprod" ) );
    if( currentProductInfo && currentProductInfo.url !== window.location.pathname )
    {
        sessionStorage.removeItem( "dw_cprod" );
        currentProductInfo = JSON.parse( sessionStorage.getItem( "dw_cprod" ) );
    }

    const destination = document.getElementById( 'form-destination' );
    const destinationPreference = document.getElementById( 'destination-preference' );
    let destinationValue = null;

    if( destination )
    {
        if( selectedDestination )
        {
            destinationValue = selectedDestination;
        }
        else
        {
            destination.addEventListener( 'change', function()
            {
                destinationValue = destination.value;

                if( destinationPreference )
                {
                    destinationPreference.innerHTML = destinationValue;
                    destinationPreference.style.display = 'inline-block';
                }
            } );
        }

        if( !selectedDestination && currentProductInfo?.formDestination )
        {
            destination.value = currentProductInfo.formDestination;

            if( destinationPreference )
            {
                destinationPreference.innerHTML = currentProductInfo.formDestination;
                destinationPreference.style.display = 'inline-block';
            }
        }
        else
        {
            if( destinationPreference )
            {
                destinationPreference.innerHTML = destinationValue;
                destinationPreference.style.display = 'inline-block';
            }
        }
    }
}

export function togglePopup( id, showMask, fixPosition )
{
    try
    {
        const popup = document.getElementById( id );
        const popupsParent = document.getElementById( 'popups-parent' );
        const body = document.getElementsByTagName( 'body' )[0];
        const openedPopup = document.querySelector( '[data-opened-popup="true"]' );

        if( openedPopup && popup.id !== openedPopup.id && !popup.id.startsWith( 'fullScreenSlider' ) )
        {
            openedPopup.removeAttribute( 'data-opened-popup' );
            openedPopup.style.display = 'none';
        }

        if( popup )
        {
            if( !popup.id.startsWith( 'fullScreenSlider' ) )
            {
                popup.setAttribute( 'data-opened-popup', true );
            }
            popup.style.display = popup.style.display === 'block' ? 'none' : 'block';

            if( showMask )
            {
                handlePopupWindowPosition( popup );
                handlePopupAppendToParent( body, popup, popupsParent );

            }
            else if( fixPosition )
            {
                handlePopupWindowPosition( popup );

                popup.setAttribute( 'data-appended', true );

                if( window.innerWidth < 768 )
                {
                    handlePopupAppendToParent( body, popup, popupsParent );
                }
            }
        }
    }
    catch( e )
    {
        console.log( 'Error finding popup: ' + e );
    }
};

function handlePopupAppendToParent( body, popup, popupsParent )
{
    popup.style.display === 'block' ? body.classList.add( 'body-overflow' ) : body.classList.remove( 'body-overflow' );
    popup.setAttribute( 'data-appended', true );
    popupsParent.appendChild( popup );
    document.getElementById( 'page-mask' ).style.display = popup.style.display === 'block' ? 'block' : 'none';
}

function handlePopupWindowPosition( popup )
{
    let topPosition = null;
    let leftPosition = null;
    let bodyWidth = null;

    // Code to compensate for admin bar pushing all popups lower than they should be when in edit mode
    const adminbar = document.getElementById( 'apos-admin-bar' );
    const parentPosition = popup.parentElement.getBoundingClientRect();
    parentPosition.y = adminbar ? parentPosition.y - 112 : parentPosition.y;

    const navbarPointer = document.getElementById( 'navbar-pointer' );
    if( popup.id === 'navbar-destinations-popup' )
    {
        // DESTINATION NAVBAR POPUP
        navbarPointer.style.display = navbarPointer.style.display === 'block' ? 'none' : 'block';
        topPosition = parentPosition.y + parentPosition.height + 30;

        navbarPointer.style.top = parentPosition.y + parentPosition.height + 14 + 'px';
        navbarPointer.style.left = parentPosition.left - 57 + 'px';
        popup.style.top = topPosition + 'px';
    }
    else if( popup.id === 'themas-popup' )
    {
        // THEMA'S NAVBAR POPUP
        document.getElementById( 'navbar-pointer' ).style.display = 'block';
        topPosition = parentPosition.y + parentPosition.height + 30;
        leftPosition = parentPosition.left - 125;

        navbarPointer.style.top = parentPosition.y + parentPosition.height + 14 + 'px';
        navbarPointer.style.left = parentPosition.left - 35 + 'px';
        popup.style.top = topPosition + 'px';
        popup.style.left = leftPosition + 20 + 'px';
    }
    else if( popup.id === 'search-popup' )
    {
        // TEXTUAL SEARCH POPUP
        topPosition = parentPosition.y + parentPosition.height + 22;
        const popupPosition = document.getElementById( 'navbar-input-search-container' ).getBoundingClientRect();

        if( bodyWidth > 767 )
        {
            popup.style.top = topPosition + 'px';
        }
        popup.style.left = popupPosition.left + 'px';
    }
    else if( popup.parentElement.hasAttribute( 'data-searchBar' ) )
    {
        // SEARCHBAR POPUP
        topPosition = parentPosition.y + parentPosition.height + 17 + document.documentElement.scrollTop;
        bodyWidth = Math.max( document.documentElement.clientWidth || 0, window.innerWidth || 0 );
        const parentWidth = parentPosition.width - 38;

        popup.style.top = topPosition + 'px';
        popup.style.left = parentPosition.left + 'px';

        if( bodyWidth > 767 && bodyWidth < 1183 )
        {
            popup.style.maxWidth = popup.parentElement.id === 'search-bar-travelers-container' ? parentWidth + 'px' : '1200px';

            if( document.getElementsByClassName( 'booking-steps-container' ).length > 0 || document.getElementsByClassName( 'pd-sticky-wrapper' ).length > 0 )
            {
                window.scrollTo( 0, topPosition - 155 );
            }
            else
            {
                window.scrollTo( 0, topPosition - 94 );
            }
        }

        const searchMenuElements = document.querySelectorAll( '[data-searchBar]' );
        const lastMenuElement = searchMenuElements[searchMenuElements.length - 1];

        if( lastMenuElement.id === popup.parentElement.id && ( bodyWidth > 767 && bodyWidth < 1590 ) )
        {
            popup.style.width = parentPosition.width - 40 + 'px';
        }
    }
    else if( popup.id.startsWith( 'scuba-rating-popup-' ) && popup.id !== 'scuba-rating-popup-20' )
    {
        // SCUBA RATING POPUP
        topPosition = parentPosition.y + parentPosition.height + 17 + document.documentElement.scrollTop;
        bodyWidth = Math.max( document.documentElement.clientWidth || 0, window.innerWidth || 0 );

        if( parentPosition.height > 30 )
        {
            topPosition = parentPosition.y + 74 + document.documentElement.scrollTop;
        }

        if( bodyWidth > 767 )
        {
            popup.style.top = topPosition + 'px';
        }

        if( bodyWidth > 1488 )
        {
            popup.style.marginLeft = '-' + 44 + 'px';
        }

        if( parentPosition.height > 30 && bodyWidth > 1488 )
        {
            popup.style.marginLeft = '-' + 100 + 'px';
        }
    }
    else if( popup.id === 'scuba-rating-popup-20' )
    {
        bodyWidth = Math.max( document.documentElement.clientWidth || 0, window.innerWidth || 0 );
        const testimonialParentPosition = document.getElementById( 'testimonal-parent' ).getBoundingClientRect();

        if( bodyWidth > 767 )
        {
            popup.style.left = testimonialParentPosition.left - 53 + 'px';
            popup.style.top = testimonialParentPosition.height + 40 + 'px';
        }
        else
        {
            popup.style.left = 0;
        }

        if( popup.parentElement.classList.contains( 'hero-wrapper' ) )
        {
            if( bodyWidth > 767 )
            {
                popup.style.top = testimonialParentPosition.height + 300 + 'px';
            }

            if( bodyWidth > 1530 )
            {
                popup.style.left = -32 + 'px';
            }
            else if( bodyWidth > 767 )
            {
                popup.style.left = 16 + 'px';
            }
        }
    }
    else if( popup.id.startsWith( 'ptAccordion-price-table-' ) )
    {
        const parentId = popup.id.split( 'ptAccordion-price-table-' )[1];
        const roomDetailsParent = document.getElementById( 'pt-room-' + parentId ).getBoundingClientRect();
        popup.style.top = document.documentElement.scrollTop + roomDetailsParent.y - roomDetailsParent.height + 'px';
    }
    else if( popup.id.startsWith( 'cs-' ) )
    {
        // CUSTOM SELECT POPUP
        const pageMask = document.getElementById( 'page-mask' );
        topPosition = parentPosition.y + parentPosition.height + 17 + document.documentElement.scrollTop;
        if( window.innerWidth > 550 )
        {
            popup.style.top = topPosition - 18 + 'px';
            popup.style.left = parentPosition.left + 'px';
            popup.style.width = parentPosition.width - 42 + 'px';
            pageMask.style.opacity = 0;
        }
    }
    else if( popup.id.startsWith( 'ptAccordion-diving-center' ) )
    {
        const divingParentId = popup.id.split( 'ptAccordion-diving-center-' )[1];
        const divingCenterParent = document.getElementById( 'pt-diving-center-' + divingParentId ).getBoundingClientRect();
        popup.style.top = document.documentElement.scrollTop + divingCenterParent.y - divingCenterParent.height - 100 + 'px';
    }
}

function movePopupBackToOriginalParent( parent, popup )
{
    const parentDiv = document.getElementById( parent );
    const popupDiv = document.getElementById( popup );
    const pageMask = document.getElementById( 'page-mask' );
    document.getElementById( 'navbar-pointer' ).style.display = 'none';
    document.getElementsByTagName( 'body' )[0].classList.toggle( 'body-overflow' );

    popupDiv.style.top = null;
    popupDiv.style.left = null;
    popupDiv.style.display = 'none';
    pageMask.style.display = 'none';

    parentDiv.appendChild( popupDiv );
}

function closeActivePopupWithMask()
{
    const popupParent = document.getElementById( 'popups-parent' );
    const activePopup = popupParent.children;
    document.getElementById( 'navbar-pointer' ).style.display = 'none';

    if( activePopup[1] && activePopup[1].id === 'navbar-destinations-popup' )
    {
        movePopupBackToOriginalParent( 'nav-destination-parent', activePopup[1].id );
    }

    if( activePopup[1] && activePopup[1].id === 'themas-popup' )
    {
        movePopupBackToOriginalParent( 'nav-themas-parent', activePopup[1].id );
    }

    if( activePopup[1] && activePopup[1].id === 'search-popup' )
    {
        moveSearchInputBackToMenu();
    }

    if( activePopup[1] && activePopup[1].id === 'search-bar-destination-popup' )
    {
        movePopupBackToOriginalParent( 'search-bar-destination-container', activePopup[1].id );
        toggleClass( document.getElementById( 'search-bar-destination-popup' ), 'search-popup-opened' );
    }

    if( activePopup[1] && activePopup[1].id === 'search-bar-type-popup' )
    {
        movePopupBackToOriginalParent( 'search-bar-type-container', activePopup[1].id );
        toggleClass( document.getElementById( 'search-bar-type-popup' ), 'search-popup-opened' );
    }

    if( activePopup[1] && activePopup[1].id === 'search-bar-price-type-3-popup' )
    {
        movePopupBackToOriginalParent( 'search-bar-price-type-3-container', activePopup[1].id );
        toggleClass( document.getElementById( 'search-bar-price-type-3-popup' ), 'search-popup-opened' );
    }

    if( activePopup[1] && activePopup[1].id === 'search-bar-litepicker-wrapper' )
    {
        movePopupBackToOriginalParent( 'search-bar-date-container', activePopup[1].id );
        toggleClass( document.getElementById( 'search-bar-litepicker-wrapper' ), 'search-popup-opened' );
    }

    if( activePopup[1] && activePopup[1].id === 'search-bar-duration-popup' )
    {
        movePopupBackToOriginalParent( 'search-bar-duration-container', activePopup[1].id );
        toggleClass( document.getElementById( 'search-bar-duration-popup' ), 'search-popup-opened' );
    }

    if( activePopup[1] && activePopup[1].id === 'search-bar-travelers-popup' )
    {
        movePopupBackToOriginalParent( 'search-bar-travelers-container', activePopup[1].id );
        toggleClass( document.getElementById( 'search-bar-travelers-popup' ), 'search-popup-opened' );
    }

    if( activePopup[1] && activePopup[1].id.startsWith( 'product-detail-masonry-popup-' ) )
    {
        togglePopup( activePopup[1].id, true );
        movePopupToLayout( activePopup[1] );
    }

    if( activePopup[1] && activePopup[1].id.startsWith( 'ptAccordion-price-table-' ) )
    {
        document.getElementById( 'page-mask' ).style.display = 'none';
        movePopupBackToOriginalParent( 'pricesTable', activePopup[1].id );
    }

    if( activePopup[1] && activePopup[1].id.startsWith( 'ptAccordion-diving-center-' ) )
    {
        document.getElementById( 'page-mask' ).style.display = 'none';
        movePopupBackToOriginalParent( 'divingCenters', activePopup[1].id );
    }

    if( activePopup[1] && activePopup[1].id.startsWith( 'cs-' ) )
    {
        closeCustomSelectPopup();
    }
}

function movePopupToLayout( popup )
{
    if( !( popup.id.startsWith( 'scuba-rating-popup-' ) && window.innerWidth > 767 ) )
    {
        document.querySelector( '[data-apos-refreshable]' ).append( popup );
    }
}

export function closeCustomSelectPopup()
{
    const popupParent = document.getElementById( 'popups-parent' );
    const activePopup = popupParent.children;
    document.getElementById( 'navbar-pointer' ).style.display = 'none';

    if( activePopup[1] && activePopup[1].id.startsWith( 'cs-' ) )
    {
        const parentId = activePopup[1].id.replace( 'custom-select-', '' ).replace( '-popup', '' );
        const parentDiv = document.getElementById( parentId );
        const popupDiv = document.getElementById( activePopup[1].id );
        const pageMask = document.getElementById( 'page-mask' );
        document.getElementById( 'navbar-pointer' ).style.display = 'none';
        document.getElementsByTagName( 'body' )[0].classList.toggle( 'body-overflow' );

        popupDiv.style.top = null;
        popupDiv.style.left = null;
        popupDiv.style.display = 'none';
        pageMask.style.display = 'none';
        pageMask.style.opacity = 'inherit';

        parentDiv.appendChild( popupDiv );
        if( activePopup[1] )
        {
            activePopup[1].remove();
        }
    }
}

export function removePopup( popupId )
{
    const popup = document.getElementById( popupId );
    const pageMask = document.getElementById( 'page-mask' );
    if( popup )
    {
        popup.remove();
        pageMask.style.display = 'none';
    }
}

export function openPopup( popupId )
{
    const popup = document.getElementById( popupId );
    if( popup )
    {
        popup.style.display = 'block';
    }
}

export function closePopup( popupId )
{
    const popup = document.getElementById( popupId );
    if( popup )
    {
        popup.style.display = 'none';
    }
}
