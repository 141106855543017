import {
    setMessageSession,
    showMessage
} from '../../../../asset/ui/src';

export default () =>
{

};

export async function submitNewsletter( formId, recaptchaResponse )
{
    const form = document.getElementById( formId );
    const button = form.querySelector( 'button[id="submit_' + formId + '"]' );
    button.classList.add( 'disable-submit' );
    const email = form.querySelector( 'input[name="email"]' );

    const rawEmails = {};
    rawEmails.email = email && email.value ? email.value : null;

    const newsletterFormData = [
        {
            type: 'email',
            value: rawEmails
        }
    ];

    const response = await apos.http.post( '/launderData', { body: newsletterFormData } );
    if( response.status === 'OK' && response.data )
    {
        let errorCounter = 0;
        if( !response.data.email )
        {
            email.parentElement.classList.add( 'input-invalid' );
            errorCounter++;
        }
        if( errorCounter > 0 )
        {
            button.classList.remove( 'disable-submit' );
            return;
        }
        const formData = new FormData();
        formData.append( 'u', '1' );
        formData.append( 'f', '1' );
        formData.append( 's', '' );
        formData.append( 'c', '0' );
        formData.append( 'm', '0' );
        formData.append( 'act', 'sub' );
        formData.append( 'v', '2' );
        formData.append( 'or', '44f8f1b8c7c70852ca1e7436dd477165' );
        formData.append( 'email', response.data.email );

        const newData = {};
        newData.recaptchaResponse = recaptchaResponse;
        newData.formData = convertFormDataToJSON( formData ); // must be serialized

        subscribeToNewsletter( newData, button, form );
    }
    else
    {
        console.log( '-> response', response );
    }
}

async function subscribeToNewsletter( newsletterFormData, newsletterFormButton, form )
{
    const response = await apos.http.post( '/subscribeToNewsletter', { body: { newsletterFormData } } );
    if( response.status === 'OK' )
    {
        setMessageSession( response.status, response.message, form.id );
        showMessage( response.status, response.message, form.id, 10000 );
        newsletterFormButton.classList.remove( 'disable-submit' );
        document.getElementById( form.id ).reset();
        pushNewsletterFormDataLayer( newsletterFormData );
        if( response.redirectUrl )
        {
            window.open( response.redirectUrl, '_blank' );
        }
    }
    else
    {
        showMessage( response.status, response.message, form.id, 10000 );
        newsletterFormButton.classList.remove( 'disable-submit' );
    }

    return false;
}

function pushNewsletterFormDataLayer( data )
{
    const dataLayer = window.dataLayer || [];
    dataLayer.push( { ecommerce: null } ); // Clear the previous ecommerce object
    dataLayer.push( {
        event: 'form_newsletter',
        'form.email': data.formData.email
    } );
}

function convertFormDataToJSON( formData )
{
    const jsonObject = {};
    formData.forEach( function ( value, key )
    {
        jsonObject[key] = value;
    } );
    return jsonObject;
}

// old function, currently not in use
// function handleNewsletterForm( formId, recaptchaResponse )
// {
//     var form = document.getElementById( formId );
//     var button = form.querySelector( 'button[id="submit_' + formId + '"]' );
//     button.classList.add( 'disable-submit' );
//     var newsletterEmail = form.querySelector( 'input[name="email"]' );
//     var rawEmails = {};
//     rawEmails.email = newsletterEmail && newsletterEmail.value ? newsletterEmail.value : null;
//
//     var newsletterFormData = [
//         {
//             type: 'email',
//             value: rawEmails
//         }
//     ];
//
//     apos.utils.post( '/launderData', newsletterFormData, function( err, response )
//     {
//         if( response.status === 'OK' && response.data )
//         {
//             var errorCounter = 0;
//             if( !response.data.email )
//             {
//                 newsletterEmail.parentElement.classList.add( 'input-invalid' );
//                 errorCounter++;
//             }
//             if( errorCounter > 0 )
//             {
//                 button.classList.remove( 'disable-submit' );
//                 return;
//             }
//
//             var newData = Object.assign({}, response.data);
//             newData.recaptchaResponse = recaptchaResponse;
//
//             subscribeToNewsletter( newData, button, form );
//         }
//         else
//         {
//             console.log( "-> response", response );
//         }
//     } );
// }
