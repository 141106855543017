export default () =>
{
    apos.util.onReady( () =>
    {
        apos.util.runPlayers();
    } );

    apos.util.widgetPlayers.navbarDestinations =
      {
          selector: '[id="nav-destination-parent"]',
          player: function( el )
          {
              handleNavbarDestinations();
          }
      };
};

function handleNavbarDestinations()
{
    const navbarTabsContainer = document.querySelector( '.navbar-destination-tabs-container' );
    if( navbarTabsContainer )
    {
        const navbarDestinationTabs = navbarTabsContainer.querySelectorAll( '.navbar-destination-tab' );
        const navbarSubdestinations = document.querySelectorAll( '.navbar-subdestinations-container' );

        navbarTabsContainer.addEventListener( 'click', function( e )
        {
            e.preventDefault();
            const activeNavbarTab = e.target.closest( 'button' );
            if( activeNavbarTab )
            {
                for( let i = 0; i < navbarDestinationTabs.length; i++ )
                {
                    if( navbarDestinationTabs[i] === activeNavbarTab )
                    {
                        activeNavbarTab.classList.add( 'navbar-destination-tab-active' );
                        navbarSubdestinations[i].classList.add( 'navbar-subdestination-active' );
                    }
                    else
                    {
                        navbarDestinationTabs[i].classList.remove( 'navbar-destination-tab-active' );
                        navbarSubdestinations[i].classList.remove( 'navbar-subdestination-active' );
                    }
                }
            }
        } );
    }
}
