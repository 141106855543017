// Create cookie
export function setCookie( name, value, days )
{
    const cookieDomain = window.location.hostname;
    let expires = '';

    if( days )
    {
        if( parseInt( days ) < 0 )
        {
            expires = '; expires=Thu, 01 Jan 1970 00:00:00 GMT';
        }
        else
        {
            const date = new Date();
            date.setTime( date.getTime() + days * 24 * 60 * 60 * 1000 );
            expires = '; expires=' + date.toGMTString();
        }
    }

    document.cookie = name + '=' + value + expires + ';path=/;domain=' + cookieDomain;
};

export function getCookie( cookieName )
{
    const matches = document.cookie.match( new RegExp(
        '(?:^|; )' + cookieName.replace( /([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1' ) + '=([^;]*)'
    ) );

    return matches ? decodeURIComponent( matches[1] ) : undefined;
};

export function clearCookie( cookieName, keysArray )
{
    //get cookie
    const foundCookie = getCookie( cookieName );
    if( foundCookie )
    {
        const cookie = JSON.parse( foundCookie );

        //if keys is empty array, delete cookie
        if( !keysArray || keysArray.length === 0 )
        {
            deleteCookie( cookieName );
            return;
        }

        //clear data in provided keys
        keysArray.forEach( function ( key )
        {
            cookie[key] = null;
        } );

        setCookie( cookieName, JSON.stringify( cookie ), 1 );
    }
};

export function deleteCookie( name )
{
    setCookie( name, '', -1 );
};
