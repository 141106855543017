import { getCookie } from './cookies';

export function pushSearchBarDataLayer()
{
    const dataLayer = window.dataLayer || [];
    const searchBarCookie = JSON.parse( getCookie( 'dw_sbd' ) );

    const type = searchBarCookie.destinationsType && searchBarCookie.destinationsType !== '' ? searchBarCookie.destinationsType.label : 'Alle';
    const destinations = searchBarCookie.countryAreas && searchBarCookie.countryAreas.length > 0
        ? searchBarCookie.countryAreas.map( function( countryArea )
        {
            return countryArea.label.toUpperCase();
        } ).join( ', ' )
        : '';
    const date = searchBarCookie.date && searchBarCookie.date !== '' ? searchBarCookie.date : '';
    const travelers = searchBarCookie.travelers;

    dataLayer.push( {
        event: 'searchbox',
        searchbox_values: {
            type: type,
            destination: destinations,
            date: date,
            travelers: travelers
        }
    } );
}

export function pushListPageDataLayer( dlData )
{
    if( !dlData || ( dlData && dlData.items && dlData.items.length === 0 ) )
    {
        return;
    }

    const dataLayer = window.dataLayer || [];
    dataLayer.push( { ecommerce: null } ); // Clear the previous ecommerce object

    const items = [];
    dlData.items.forEach( function( item, index )
    {
        const dlItem = {
            item_id: item.item_id,
            item_name: item.item_name,
            index: index,
            item_category: item.item_category,
            price: item.price,
            quantity: 1,
            country: item.country, //custom parameters from here
            locations: item.locations,
            offer: item.offer,
            selection: item.selection
        };

        items.push( dlItem );
    } );

    dataLayer.push( {
        event: 'view_item_list',
        ecommerce: {
            item_list_id: dlData.item_list_id,
            item_list_name: dlData.item_list_name,
            items: items
        }
    } );
}

export function pushDetailsPageDataLayer( item, value, ratingScore )
{
    const dataLayer = window.dataLayer || [];
    dataLayer.push( { ecommerce: null } ); // Clear the previous ecommerce object

    const rating = ratingScore ? parseFloat( ratingScore ) : null;

    const dlItem = {
        item_id: item.item_id,
        item_name: item.item_name,
        index: 0,
        item_category: item.item_category,
        price: value,
        quantity: 1,
        country: item.country, //custom parameters from here
        locations: item.locations,
        offer: item.offer,
        selection: item.selection,
        rating: rating
    };

    dataLayer.push( {
        event: 'view_item',
        ecommerce: {
            currency: 'EUR',
            value: value,
            items: [ dlItem ]
        }
    } );
}

export function pushBookingPageDataLayer( data )
{
    const dataLayer = window.dataLayer || [];
    dataLayer.push( { ecommerce: null } ); // Clear the previous ecommerce object
    dataLayer.push( data );
}

export function pushBookingConfirmationPageDataLayer( data )
{
    const dataLayer = window.dataLayer || [];
    dataLayer.push( { ecommerce: null } ); // Clear the previous ecommerce object
    dataLayer.push( data );
}

export function pushOfferFormDataLayer( data )
{
    const dataLayer = window.dataLayer || [];
    dataLayer.push( { ecommerce: null } ); // Clear the previous ecommerce object
    dataLayer.push( {
        event: 'form_offer',
        'form.first_name': data.firstName,
        'form.surname': data.lastName,
        'form.email': data.email,
        'form.phone_number': data.phone,
        'form.city': data.residence,
        'form.country': data.country,
        'form.date_of_departure': data.firstDeparture,
        'form.return_date': data.lastDeparture,
        'form.travel_duration': data.desiredTravelTime,
        'form.number_of_adults': data.adultNumber,
        'form.number_of_children': data.childrenNumber,
        'form.destination': data.destination,
        'form.additional_information': data.advice ? 'Yes' : 'No',
        'form.message': data.additionalInformation,
        'form.subscribed': data.newsletter ? 'Yes' : 'No'
    } );
}

export function pushPersonalTravelAdviceFormDataLayer( data )
{
    const dataLayer = window.dataLayer || [];
    dataLayer.push( { ecommerce: null } ); // Clear the previous ecommerce object
    dataLayer.push( data );
}
