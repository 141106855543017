import './js/htmx.2.0.0.min.js';
import './js/bundle.js';
import './js/alpine3.14.1.js';

import { submitNewsletter } from '../../../widgets/newsletter-subscription-widget/ui/src';
import { initializeFullScreenSlider, initializeSliders } from './js/sliders';
import {
    setDestinationPreference,
    togglePopup,
    closeCustomSelectPopup
} from './js/popups';
import {
    setCookie, getCookie, clearCookie, deleteCookie
} from './js/cookies';
import {
    pushOfferFormDataLayer,
    pushPersonalTravelAdviceFormDataLayer
} from './js/data-layers';

export let mobileScope = false;

// Getter Functions
export function getMobileScope()
{
    return mobileScope;
}

// Set functions
export function setMobileScope( value )
{
    mobileScope = value;
}

export default () =>
{
    apos.util.onReady( () =>
    {
        initializeDatePickers();
        initializeSliders();
        displayShowMoreButtons();

        document.addEventListener( "htmx:afterSwap", function( event )
        {
            handleHTMXEvents( event, 'data-after-swap' );
        } );
    } );
};

function handleHTMXEvents( event, dataAttributeName )
{
    const nodes = [...event.detail.target.childNodes];
    const swappedNode = nodes.find( node => node.attributes?.getNamedItem( dataAttributeName ) );
    if( swappedNode )
    {
        const functionsDataAttribute = swappedNode.getAttribute( dataAttributeName ) || null;
        if( functionsDataAttribute )
        {
            const functionsArray = JSON.parse( functionsDataAttribute.split( ',' ) );
            if( functionsArray )
            {
                functionsArray.forEach( function( item )
                {
                    const functionName = item.f;
                    const params = item.p;

                    if( typeof window[functionName] === 'function' )
                    {
                        window[functionName]( ...params );
                    }
                    else
                    {
                        console.error( `Function ${ functionName } does not exist on window.` );
                    }
                } );
            }
        }
    }
}

const windowFunctions = {
    navigateToUrl: navigateToUrl,
    navigateToUrlAndSetSearchBarData: navigateToUrlAndSetSearchBarData,
    navigateToUrlWithinViewportAndSetSearchBarData: navigateToUrlWithinViewportAndSetSearchBarData,
    handleCounter: handleCounter,
    toggleClass: toggleClass,
    setDefaultSearchBarData: setDefaultSearchBarData,
    clearFiltersAndReloadPage: clearFiltersAndReloadPage,
    getSearchResults: getSearchResults,
    toggleClassForId: toggleClassForId,
    addNewsletterRecaptcha: addNewsletterRecaptcha,
    removeClassFromElement: removeClassFromElement,
    switchTab: switchTab,
    toggleExtraInfo: toggleExtraInfo,
    displayShowMoreButtons: displayShowMoreButtons,
    toggleShowMore: toggleShowMore,
    scrollToElement: scrollToElement,
    handleCustomSelector: handleCustomSelector,
    moveSearchInputToLayout: moveSearchInputToLayout,
    moveSearchInputBackToMenu: moveSearchInputBackToMenu,
    showSpinnerOnSearchNavigationAndClearSearchBar: showSpinnerOnSearchNavigationAndClearSearchBar,
    showMasonryGallery: showMasonryGallery,
    toggleFooterLinks: toggleFooterLinks,
    handleCollapsibleElement: handleCollapsibleElement,
    scrollToTop: scrollToTop,
    requestOffer: requestOffer,
    limitInputNumber: limitInputNumber,
    createChildAgeInput: createChildAgeInput,
    positionMobileCloseButtonOnSearchPopup: positionMobileCloseButtonOnSearchPopup,
    selectCustomSelectorDropdownInForm: selectCustomSelectorDropdownInForm,
    showBookingOverview: showBookingOverview,
    submitPersonalTravelAdvice: submitPersonalTravelAdvice,
    createTravelerFromTemplate: createTravelerFromTemplate,
    removeLastStaticBookingTraveler: removeLastStaticBookingTraveler,
    submitStaticBooking: submitStaticBooking,
    toggleSpinner: toggleSpinner,
    addRecaptcha: addRecaptcha,
    initializeDatePickers: initializeDatePickers,
    initializeBirthDatePickers: initializeBirthDatePickers,
    toggleMask: toggleMask
};
Object.assign( window, windowFunctions );

export function navigateToUrl( url, target, keepSearchBarData )
{
    if( !url || ( url && url === '' ) )
    {
        return;
    }

    if( !keepSearchBarData )
    {
        clearCookie( 'dw_sbd' );
        setCookie( 'dw_sbd', JSON.stringify( getDefaultSearchBarObj() ) );
    }

    if( target && target !== '' )
    {
        window.open( url, target );
    }
    else
    {
        window.location = url;
    }
};

export function getDefaultSearchBarObj()
{
    return {
        countryAreas: null,
        destinationsType: null,
        parentDestination: null,
        seoFriendlyName: null,
        date: null,
        flexibleDates: false,
        duration: null,
        travelers: 2,
        priceType3: null
    };
}

export function toggleClass( element, className, toggleAllOccurrences )
{
    if( typeof element === 'string' && element.startsWith( 'id-' ) )
    {
        element = element.replace( 'id-', '' );
        element = document.querySelector( '#' + element );
    }

    if( toggleAllOccurrences === true )
    {
        const similarElements = document.getElementsByClassName( element.classList[0] );
        for( let i = 0; i < similarElements.length; i++ )
        {
            similarElements[i].classList.remove( className );
        }
        element.classList.add( className );
    }
    else
    {
        element.classList.toggle( className );
    }
};

export function initializeDatePickers()
{
    const maxDate = new Date();
    const minDate = new Date() - 1;
    maxDate.setFullYear( maxDate.getFullYear() + 2 );

    const elements = document.querySelectorAll( '.date-picker' );
    if( elements )
    {
        elements.forEach( function( element )
        {
            if( !element.hasAttribute( 'date-initialized' ) )
            {
                const travelerPicker = new window.Litepicker( {
                    element: element,
                    format: 'DD-MM-YYYY',
                    lang: 'nl',
                    singleMode: true,
                    minDate: minDate,
                    maxDate: maxDate,
                    dropdowns: {
                        minYear: new Date().getFullYear() - 100,
                        maxYear: new Date().getFullYear(),
                        months: true,
                        years: true
                    }
                } );

                travelerPicker.on( 'render', function ( ui )
                {
                    ui.classList.add( 'simple-datepicker' );
                } );

                element.setAttribute( 'date-initialized', true );
            }
        } );
    }
}

export function setDefaultSearchBarData()
{
    setCookie( 'dw_sbd', JSON.stringify( getDefaultSearchBarObj() ) );
};

function addNewsletterRecaptcha( formId, key )
{
    const form = document.getElementById( formId );
    const button = form.querySelector( 'button[id="submit_' + formId + '"]' );

    const existingScript = document.querySelector( 'script[src="https://www.google.com/recaptcha/api.js?render=' + key + '"]' );
    if( !existingScript )
    {
        const scriptEl = document.createElement( 'script' );
        scriptEl.type = 'text/javascript';
        scriptEl.src = 'https://www.google.com/recaptcha/api.js?render=' + key;

        document.body.appendChild( scriptEl );
    }

    if( button && button.className.indexOf( 'g-recaptcha' ) === -1 )
    {
        button.className += ' g-recaptcha';
    }

    form.onsubmit = function ( event )
    {
        event.preventDefault(); // Prevent the default form submission

        grecaptcha.ready( function ()
        {
            grecaptcha.execute( key, { action: 'newsletter' } ).then( function ( token )
            {
                // handleNewsletterForm(formId, token);
                // this method got replaced
                submitNewsletter( formId, token );
            } );
        } );
    };
}

export function addRecaptcha( formId )
{
    const form = document.getElementById( formId );
    const formSubmitButton = form.querySelector( '[type="submit"]' );
    const recaptchaPublicKey = formSubmitButton.getAttribute( 'data-sitekey' );

    const existingScript = document.querySelector( 'script[src="https://www.google.com/recaptcha/api.js?render=' + recaptchaPublicKey + '"]' );
    if( !existingScript )
    {
        const scriptEl = document.createElement( 'script' );
        scriptEl.type = 'text/javascript';
        scriptEl.src = 'https://www.google.com/recaptcha/api.js?render=' + recaptchaPublicKey;
        document.body.appendChild( scriptEl );
    }

    const dataArray = formSubmitButton.getAttribute( 'data-call-function' ).split( ',' );
    const fnString = dataArray.shift();
    const fnParams = dataArray;
    const fn = window[fnString];
    formSubmitButton.className += ' g-recaptcha';
    formSubmitButton.addEventListener( 'click', function( e )
    {
        e.preventDefault();
        if( typeof fn === 'function' )
        {
            fnParams.push();
            fn.apply( null, fnParams );
        };
    } );

    // grecaptcha.ready( function() {
    //     grecaptcha.execute( recaptchaPublicKey, { action: 'submit' } ).then( function( token ){
    //         if (typeof fn === "function")
    //         {
    //             fnParams.push( token );
    //             fn.apply( null, fnParams );
    //         };
    //     });
    // });
}

function displayShowMoreButtons()
{
    const texts = document.querySelectorAll( '.text-read-more' );
    texts.forEach( function( text )
    {
        const content = texts[0];
        if( content.scrollHeight > text.clientHeight )
        {
            text.parentElement.querySelector( '.text-read-more-blend' ).style.display = 'block';
            text.parentElement.querySelector( '.text-read-more-button' ).style.display = 'block';
        }
    } );
}

function serializeForm( formData, stringify )
{
    const formDataArray = Array.from( formData );
    const obj = {};

    formDataArray.forEach( function ( element )
    {
        obj[element[0]] = element[1];
    } );

    return stringify ? JSON.stringify( obj ) : obj;
}

export function reloadPage( timeout )
{
    const timeoutSet = timeout || 0;
    setTimeout( function()
    {
        window.location.reload();
    }, timeoutSet );
}

function navigateToUrlWithinViewportAndSetSearchBarData( screenWidth, url, startDate, duration, priceType3Code, priceType3Label, nightsAtAccommodation, durationWithFlight, cruiseOnly, cataloguePrice, cruiseFlightWithPrice )
{
    if( window.innerWidth <= parseInt( screenWidth ) )
    {
        navigateToUrlAndSetSearchBarData( url, startDate, duration, priceType3Code, priceType3Label, nightsAtAccommodation, durationWithFlight, cruiseOnly, cataloguePrice, cruiseFlightWithPrice );
    }
}

export function initializeBirthDatePickers()
{
    const maxDate = new Date();
    const minDate = null;

    const elements = document.querySelectorAll( '.birth-date-picker' );
    if( elements )
    {
        elements.forEach( function( element )
        {
            if( !element.hasAttribute( 'date-initialized' ) )
            {
                const birthDatePicker = new window.Litepicker( {
                    element: element,
                    format: 'DD-MM-YYYY',
                    singleMode: true,
                    minDate: minDate,
                    maxDate: maxDate,
                    dropdowns: {
                        minYear: new Date().getFullYear() - 100,
                        maxYear: new Date().getFullYear(),
                        months: true,
                        years: true
                    }
                } );

                birthDatePicker.on( 'render', function ( ui )
                {
                    ui.classList.add( 'simple-datepicker' );
                } );

                element.setAttribute( 'date-initialized', true );
            }
        } );
    }
}

function scrollToTop()
{
    // Remove anchor from location in case it exists in browser's URL
    const locationWithoutAnchor = window.location.href.substring( 0, window.location.href.indexOf( '#' ) );
    history.pushState( {}, '', locationWithoutAnchor );
    window.scrollTo( {
        top: 0,
        behavior: 'smooth'
    } );
}

export function scrollToElement( id )
{
    history.pushState( {}, '', '#' + id );
    const el = document.getElementById( id );
    window.scroll( {
        top: el.offsetTop - 90,
        behavior: 'smooth'
    } );

    return false;
}

export function scrollToElementAndAddClass( element, className, parentElement )
{
    const scrollParent = parentElement || window;
    scrollParent.scroll( {
        top: element.offsetTop - 75,
        behavior: 'smooth'
    } );
    element.classList.add( className );
}

function scrollElementIntoViewAndAddClass( element, className, parentElement )
{
    const scrollParent = parentElement || window;
    scrollParent.scrollIntoView( { behavior: 'smooth' } );
    element.classList.add( className );
}

function removeClassFromElement( element, className )
{
    if( element.classList.contains( className ) )
    {
        element.classList.remove( className );
    }
}

export async function toggleShowMore( button, parentElement, className )
{
    parentElement.classList.toggle( className );
    button.innerHTML = parentElement.classList.contains( className ) ? await getTranslatedString( 'showLess' ) : await getTranslatedString( 'readMore' );
    // Force the blend to disappear since it's css needed to be modified to display "none" from "unset"
    const readMoreBlend = parentElement.querySelector( '.text-read-more-blend' );
    const tsmBoxBlend = parentElement.querySelector( '.tsm-box-blend' );
    const blend = readMoreBlend || tsmBoxBlend;
    blend.style.display = blend.style.display === 'block' ? 'none' : 'block';
}

async function toggleExtraInfo( button, parentElement, className, isStartingDate )
{
    parentElement.classList.toggle( className );
    const label = button.querySelector( 'span.product-extra-info-label' );
    const showMoreLabel = isStartingDate ? await getTranslatedString( 'showInfo' ) : await getTranslatedString( 'showMoreDepartureDates' );
    const hideMoreLabel = isStartingDate ? await getTranslatedString( 'hideInfo' ) : await getTranslatedString( 'hideStartingDates' );
    label.innerHTML = parentElement.classList.contains( className ) ? hideMoreLabel : showMoreLabel;
}

async function getSearchResults()
{
    const keepTypingPlaceholder = document.getElementById( 'textual_search_keep_typing' );

    const input = document.getElementById( 'searchInput' ).value || document.getElementById( 'mobileSearchInput' ).value;
    if( input && input.length >= 3 )
    {
        toggleSpinner( 'spinner_search_location', true );

        if( keepTypingPlaceholder )
        {
            keepTypingPlaceholder.style.display = 'none';
        }

        const response = await apos.http.post( '/buildSearchList', { body: { input: input } } );
        if( response.status === 'OK' )
        {
            toggleSpinner( 'spinner_search_location' );
            document.getElementById( 'searchListItem' ).innerHTML = response.data.results;
            document.getElementById( 'searchListSuggestions' ).style.display = response.data.resultsCount === 0 ? 'block' : 'none';
            navigateSearchResultsWithKeys();
        }
    }
    else
    {
        const keepTypingText = await getTranslatedString( 'keepTyping' );
        document.getElementById( 'searchListItem' ).innerHTML = '<div id="spinner_search_location"></div><p id="textual_search_keep_typing">' + keepTypingText + '</p>';
        document.getElementById( 'searchListSuggestions' ).style.display = 'none';
    }
}

function toggleClassForId( id, className )
{
    const el = document.getElementById( id );
    if( el )
    {
        toggleClass( el, className );
    }
}

async function requestOffer( destinationProductName, recaptchaResponse )
{
    const offerFormContainer = document.getElementById( 'offer-form' );
    const contactButton = offerFormContainer.querySelector( 'button[id="button-request-offer"]' );
    contactButton.classList.add( 'disable-submit' );
    const offerDestination = offerFormContainer.querySelector( 'input[name="destination"]' );
    const offerDestinationPreference = offerFormContainer.querySelector( 'input[name="desiredAccommodation"]' );
    const offerFirstName = offerFormContainer.querySelector( 'input[name="firstName"]' );
    const offerLastName = offerFormContainer.querySelector( 'input[name="lastName"]' );
    const offerEmail = offerFormContainer.querySelector( 'input[name="email"]' );
    const offerPhone = offerFormContainer.querySelector( 'input[name="phone"]' );
    const offerAdultsNumber = offerFormContainer.querySelector( 'input[name="numberOfAdults"]' );
    const offerChildrenNumber = offerFormContainer.querySelector( 'input[name="numberOfChildren"]' );
    const offerChildrenAges = offerFormContainer.querySelectorAll( 'input[name="childAge"]' );
    const offerResidence = offerFormContainer.querySelector( 'input[name="residence"]' );
    const offerCountry = offerFormContainer.querySelector( 'select[name="country"]' );
    const offerFirstDepartureDate = offerFormContainer.querySelector( 'input[name="earliestDepartureDate"]' );
    const offerLastDepartureDate = offerFormContainer.querySelector( 'input[name="lastestDepartureDate"]' );
    const offerDesiredTravelTime = offerFormContainer.querySelector( 'input[name="durationOfVacation"]' );
    const offerAdditionalInformation = offerFormContainer.querySelector( 'textarea[name="additionalInformation"]' );
    const offerAdvice = offerFormContainer.querySelector( 'input[name="adviceQuoteAlternative"]:checked' ).value;
    const offerNewsletter = offerFormContainer.querySelector( 'input[name="offer-newsletter"]' ).checked;

    const rawStrings = {};
    rawStrings.destination = offerDestination && offerDestination.value ? offerDestination.value : null;
    rawStrings.desiredAccommodation = offerDestinationPreference && offerDestinationPreference.value ? offerDestinationPreference.value : null;
    rawStrings.firstName = offerFirstName && offerFirstName.value ? offerFirstName.value : null;
    rawStrings.lastName = offerLastName && offerLastName.value ? offerLastName.value : null;
    rawStrings.phone = offerPhone && offerPhone.value ? offerPhone.value : null;
    rawStrings.adultNumber = offerAdultsNumber && offerAdultsNumber.value ? offerAdultsNumber.value : null;
    rawStrings.childrenNumber = offerChildrenNumber && offerChildrenNumber.value ? offerChildrenNumber.value : null;
    rawStrings.country = offerCountry && offerCountry.value ? offerCountry.value : null;

    if( offerChildrenAges && offerChildrenAges.length > 0 )
    {
        const childrenMap = new Map();

        for( let i = 0; i < offerChildrenAges.length; i++ )
        {
            const childLabel = i + 1;
            const childAge = offerChildrenAges[i] && offerChildrenAges[i].value ? offerChildrenAges[i].value : null;
            childrenMap.set( 'ageOfChild_' + childLabel, childAge );
        }

        const childrenWithAge = Object.fromEntries( childrenMap );
        Object.assign( rawStrings, childrenWithAge );
    }

    rawStrings.residence = offerResidence && offerResidence.value ? offerResidence.value : null;
    rawStrings.desiredTravelTime = offerDesiredTravelTime && offerDesiredTravelTime.value ? offerDesiredTravelTime.value : null;
    rawStrings.additionalInformation = offerAdditionalInformation && offerAdditionalInformation.value ? offerAdditionalInformation.value : null;
    rawStrings.advice = offerAdvice || 'false';
    const rawEmails = {};
    rawEmails.email = offerEmail && offerEmail.value ? offerEmail.value : null;
    const rawDates = {};
    rawDates.firstDeparture = offerFirstDepartureDate && offerFirstDepartureDate.value ? offerFirstDepartureDate.value : null;
    rawDates.lastDeparture = offerLastDepartureDate && offerLastDepartureDate.value ? offerLastDepartureDate.value : null;

    const offerFormData = [
        {
            type: 'string',
            value: rawStrings
        },
        {
            type: 'email',
            value: rawEmails
        },
        {
            type: 'date',
            value: rawDates
        }
    ];

    const response = await apos.http.post( '/launderData', { body: offerFormData } );
    if( response.status === 'OK' && response.data )
    {
        let errorCounter = 0;
        if( response.data.childrenNumber && response.data.childrenNumber > 0 )
        {
            const childrenAgeInputs = offerFormContainer.querySelectorAll( '.children-ages input' );
            for( let i = 0; i < childrenAgeInputs.length; i++ )
            {
                if( !childrenAgeInputs[i].value )
                {
                    scrollElementIntoViewAndAddClass( childrenAgeInputs[i].parentElement, 'input-invalid', childrenAgeInputs[i].parentElement.parentElement );
                    errorCounter++;
                }
            }
        }
        if( !response.data.country )
        {
            scrollElementIntoViewAndAddClass( offerCountry.parentElement, 'input-invalid', offerCountry.parentElement.parentElement );
            errorCounter++;
        }
        if( !response.data.phone )
        {
            scrollToElementAndAddClass( offerPhone.parentElement, 'input-invalid', offerFormContainer.parentElement.parentElement );
            errorCounter++;
        }
        if( !response.data.email )
        {
            scrollToElementAndAddClass( offerEmail.parentElement, 'input-invalid', offerFormContainer.parentElement.parentElement );
            errorCounter++;
        }
        if( !response.data.lastName )
        {
            scrollToElementAndAddClass( offerLastName.parentElement.parentElement, 'input-invalid', offerFormContainer.parentElement.parentElement );
            errorCounter++;
        }
        if( !response.data.firstName )
        {
            scrollToElementAndAddClass( offerFirstName.parentElement.parentElement, 'input-invalid', offerFormContainer.parentElement.parentElement );
            errorCounter++;
        }
        if( errorCounter > 0 )
        {
            contactButton.classList.remove( 'disable-submit' );
            return;
        }

        const newData = Object.assign( {}, response.data );
        newData.newsletter = offerNewsletter || 'false';
        newData.productName = destinationProductName || response.data.destination;
        const recaptchaPublicKey = contactButton.getAttribute( 'data-sitekey' );
        grecaptcha.ready( function()
        {
            grecaptcha.execute( recaptchaPublicKey, { action: 'submit' } ).then( function( token )
            {
                const recaptchaResponse = token;
                newData.recaptchaResponse = recaptchaResponse;
                // create lead with data
                createLeadOffer( newData, contactButton, offerFormContainer );
            } );
        } );
    }
    else
    {
        console.log( '-> response', response );
    }

}

async function createLeadOffer( offerFormData, offerFormButton, form )
{
    const response = await apos.http.post( '/leadOffer', { body: offerFormData } );
    if( response.status === 'OK' )
    {
        setMessageSession( response.status, response.message, 'newsletter' );
        showMessage( response.status, response.message, 'newsletter', 10000 );
        offerFormButton.classList.remove( 'disable-submit' );
        document.getElementById( form.id ).reset();
        pushOfferFormDataLayer( offerFormData );
    }
    else
    {
        showMessage( response.status, response.message, 'newsletter', 10000 );
        offerFormButton.classList.remove( 'disable-submit' );
    }
}

async function createLeadPersonalTravelAdvice( formData, button, formId )
{
    if( formData && button && formId )
    {
        const response = await apos.http.post( '/leadPersonalTravelAdvice', { body: formData } );
        // TODO: translate messages to Dutch ?
        if( response.status === 'OK' )
        {
            setMessageSession( response.status, response.message, 'pta-newsletter' );
            showMessage( response.status, response.message, 'pta-newsletter', 10000 );
            button.classList.remove( 'disable-submit' );
            document.getElementById( formId ).reset();
            createLeadPersonalTravelAdviceFormDataLayer( formData );
        }
        else
        {
            showMessage( response.status, response.message, 'pta-newsletter', 10000 );
            button.classList.remove( 'disable-submit' );
        }
    }
}

function createLeadPersonalTravelAdviceFormDataLayer( data )
{
    const dataLayer = {
        event: 'form_travel_advice',
        'form.first_name': data.personalName,
        'form.surname': data.familyName,
        'form.email': data.email,
        'form.phone_number': data.phoneNumber,
        'form.city': data.city,
        'form.country': data.country,
        'form.date_of_departure': data.startDate,
        'form.return_date': data.endDate,
        'form.travel_duration': data.duration,
        'form.school_holiday': data.vacationPeriod,
        'form.number_of_adults': data.numberOfAdults,
        'form.number_of_children': data.numberOfChildren,
        'form.budget_per_person': data.budget,
        'form.including_or_excluding_diving': data.inclusiveOrExclusive,
        'form.additional_information': data.extraInformation,
        'form.possible_destinations': data.destination,
        'form.destinations_visited': data.visitedDestinations,
        'form.subscribed': data.newsletter ? 'Yes' : 'No'
    };

    // create key value pairs from the dynamic fields
    for( const key in data )
    {
        // check main options and add them to the dataLayer
        if( data.hasOwnProperty( key ) && key.indexOf( 'personalTravelAdviceOption' ) === 0 )
        {
            const optionField = data[key];
            const optionCategory = optionField.split( '/' )[0];
            let optionCategoryLabel = null;

            // find the label for the option category
            for( const labelKey in data )
            {
                if( data.hasOwnProperty( labelKey ) && labelKey.indexOf( optionCategory ) === 0 )
                {
                    optionCategoryLabel = data[labelKey];
                }
            }

            const optionKey = 'form.' + optionCategory.replaceAll( ' ', '_' );
            dataLayer[optionKey] = optionCategoryLabel;
        }

        // check more information options and add them to the dataLayer
        if( data.hasOwnProperty( key ) && key.indexOf( 'moreInformation' ) === 0 )
        {
            const infoField = data[key];
            const category = infoField.split( '/' )[0].replaceAll( ' ', '_' );
            const option = infoField.split( '/' )[1];

            const infoKey = 'form.additional_' + category;
            dataLayer[infoKey] = option;
        }
    }

    pushPersonalTravelAdviceFormDataLayer( dataLayer );
}

async function createLeadStaticBooking( formData, button, formId )
{
    if( formData && button && formId )
    {
        const response = await apos.http.post( '/leadStaticBooking', { body: formData } );
        // TODO: translate messages to Dutch ?
        if( response.status === 'OK' )
        {
            setMessageSession( response.status, response.message, 'static-booking-confirmation-checkbox-container' );
            showMessage( response.status, response.message, 'static-booking-confirmation-checkbox-container', 10000 );
            button.classList.remove( 'disable-submit' );
            document.getElementById( formId ).reset();
        }
        else
        {
            showMessage( response.status, response.message, 'static-booking-confirmation-checkbox-container', 10000 );
            button.classList.remove( 'disable-submit' );
        }
    }
}

function limitInputNumber( input, value )
{
    if( input.value > value )
    {
        input.value = value;
    }
}

function createChildAgeInput( childrenNumberId, childrenAgesId, agesRequired )
{
    const childrenNumber = document.getElementById( childrenNumberId );
    if( childrenNumber )
    {
        const offerchildrenNumber = childrenNumber.value;
        let childrenForms = '';
        for( let i = 1; i <= offerchildrenNumber; i++ )
        {
            const childName = 'ageOfChild_' + i;
            if( agesRequired )
            {
                childrenForms += '<div class="input-group children-ages"> <label>leeftijd van kinderen ' + i + '</label>' + '<input type="number" name="childAge" id="' + childName + '"' + ' x-data @click=\'removeClassFromElement( $el.parentElement, "input-invalid" )\' ' + ' required>' + '<span class="input-error-message">Dit veld is verplicht</span></div>';
            }
            else
            {
                childrenForms += '<div class="input-group children-ages"> <label>leeftijd van kinderen ' + i + '</label>' + '<input type="number" name="childAge" id="' + childName + '" placeholder="optioneel"></div>';
            }

            if( i < offerchildrenNumber )
            {
                childrenForms += '<br>';
            }
        }
        const childrenAges = document.getElementById( childrenAgesId );
        if( childrenAges )
        {
            childrenAges.innerHTML = childrenForms;
        }
    }
}

export function switchTab( tab, tabActiveClass, contentId, contentActiveClass )
{
    if( typeof tab === 'string' && tab.startsWith( 'id-' ) )
    {
        tab = tab.replace( 'id-', '' );
        tab = document.querySelector( '#' + tab );
    }
    // toggle the active class on the tab that was pressed ( ex. change color to blue )
    toggleClass( tab, tabActiveClass, true );
    // toggle the active class on the specified id ( ex. show map-view on product list )
    const selectedView = document.getElementById( contentId );
    toggleClass( selectedView, contentActiveClass, true );
}

export function toggleSpinner( parentElId, noToggle )
{
    const spinnerId = 'spinner_' + parentElId;
    const parentEl = document.getElementById( parentElId );
    if( parentEl )
    {
        let spinner = document.getElementById( spinnerId );
        if( !spinner )
        {
            spinner = document.createElement( 'div' );
            spinner.id = spinnerId;
            spinner.classList.add( 'lds-ellipsis' );

            // for loop adds the multiple divs needed for the spinner to work properly
            for( let i = 0; i < 4; i++ )
            {
                const div = document.createElement( 'div' );
                spinner.appendChild( div );
            }

            parentEl.after( spinner );
        }
        else if( !noToggle )
        {
            spinner.parentNode.removeChild( spinner );
        }
    }
}

// function getMasonryImages( productCode )
// {
//     var aposRefreshable = document.querySelector( '.apos-refreshable' );
//     var images = document.getElementById( 'product-detail-masonry-images' );
//
//     if( !images )
//     {
//         apos.utils.post( '/getProductDetailImages', { productCode: productCode }, function( err, response )
//         {
//             if( response.status === 'OK' )
//             {
//                 aposRefreshable.insertAdjacentHTML( 'afterbegin', response.data.albumData );
//                 console.log( "-> response", response );
//             }
//             else
//             {
//                 console.log( "-> response", response );
//             }
//         } );
//     }
// }

export function handleCollapsibleElement( elementHight, newElementHight, fixedHeight )
{
    const getHightOfElement = document.getElementById( elementHight ).offsetHeight;
    const setHightOfElement = document.getElementById( newElementHight );

    if( setHightOfElement.style.height === fixedHeight + 'px' || setHightOfElement.style.height === 0 + 'px' || setHightOfElement.style.height === '' )
    {
        setHightOfElement.style.height = getHightOfElement + 'px';
    }
    else
    {
        setHightOfElement.style.height = fixedHeight ? fixedHeight + 'px' : 0 + 'px';
    }
}

function showMasonryGallery( id )
{
    togglePopup( 'product-detail-masonry-popup-' + id, true );
    toggleSpinner( 'product-detail-masonry-title-' + id );

    const popup = document.getElementById( 'product-detail-masonry-popup-' + id );
    const galleryWrapper = popup.querySelector( '#product-detail-masonry-images' );
    galleryWrapper.style.visibility = 'hidden';

    const masonryImages = popup.querySelectorAll( '#product-detail-masonry-' + id + ' .masonry-image' );
    if( masonryImages )
    {
        for( let i = 0; i < masonryImages.length; i++ )
        {
            const image = masonryImages[i];
            if( image.src && image.dataset.src && image.src !== image.dataset.src )
            {
                image.src = image.dataset.src;
            }

            if( i === masonryImages.length - 1 )
            {
                setTimeout( function( )
                {
                    toggleSpinner( 'product-detail-masonry-title-' + id );
                    galleryWrapper.style.visibility = 'visible';
                }, 500 );
            }
        }

        const fullScreenSlider = document.getElementById( 'fullScreenSlider-' + id );
        if( fullScreenSlider && fullScreenSlider.dataset.sliderInitialized === '' )
        {
            initializeFullScreenSlider( id );
            fullScreenSlider.dataset.sliderInitialized = true;
        }

    }
    else
    {
        toggleSpinner( 'product-detail-masonry-title-' + id );
        galleryWrapper.style.visibility = 'visible';
    }
}

export function moveSearchInputToLayout()
{
    const popup = document.getElementById( 'navbar-input-search-container' );
    const popupPosition = document.getElementById( 'navbar-input-search-container' ).getBoundingClientRect();
    const popupsParent = document.getElementById( 'popups-parent' );

    if( !popup.hasAttribute( 'data-appended' ) )
    {
        togglePopup( 'search-popup', true );
        document.getElementById( 'placeholderInput' ).style.display = 'block';
        popup.setAttribute( 'data-appended', true );
        popup.style.left = popupPosition.left + 'px';
        popupsParent.appendChild( popup );
    }

    document.getElementById( 'searchInput' ).focus();
}

export async function moveSearchInputBackToMenu()
{
    const popupInput = document.getElementById( 'navbar-input-search-container' );
    const hamburgerContainer = document.getElementById( 'hamburger-container' );
    const popupsParent = document.getElementById( 'navbar-right-section' );
    const popup = document.getElementById( 'search-popup' );

    // RESETS INPUT FIELD AND SEARCH RESULTS ON SEARCH POPUP CLOSE
    const keepTypingText = await getTranslatedString( 'keepTyping' );
    document.getElementById( 'searchListItem' ).innerHTML = '<p>' + keepTypingText + '</p>';
    document.getElementById( 'searchInput' ).value = '';
    document.getElementById( 'mobileSearchInput' ).value = '';

    popupInput.removeAttribute( 'data-appended' );
    if( popup.hasAttribute( 'data-appended' ) )
    {
        document.getElementById( 'placeholderInput' ).style.display = 'none';
        togglePopup( 'search-popup', true );
        popupsParent.appendChild( popupInput );
        popupsParent.appendChild( popup );
        popupsParent.appendChild( hamburgerContainer );
    }
    else
    {
        togglePopup( 'search-popup' );
    }

}

export function reloadPageWithoutParameters()
{
    const locationPathname = window.location.pathname;
    const lastChar = locationPathname.charAt( locationPathname.length - 1 );
    const lastUrlChar = lastChar !== '/' ? '/' : '';

    window.location = window.location.pathname + lastUrlChar;
}

function navigateSearchResultsWithKeys()
{
    let itemIndex = 0;
    let maxItems = 0;

    const inputField = document.getElementById( 'searchInput' );
    inputField.addEventListener( 'keydown', function( event )
    {
        const key = event.keyCode;
        const itemList = document.querySelectorAll( '[data-item-index]' );

        // RESET IF USE STARTS SEARCHING FOR SOMETHING ELSE
        if( itemList.length !== maxItems )
        {
            itemIndex = 0;
            maxItems = 0;
        }
        maxItems = itemList.length;

        //ARROW DOWN will move through the list DOWNWARDS
        if( itemList[itemIndex] && key === 40 || key === 9 && itemIndex >= 0 )
        {
            if( itemList[itemIndex - 1] )
            {
                itemList[itemIndex - 1].classList.remove( 'keyboard-selected-result' );
            }

            itemList[itemIndex].scrollIntoView( {
                behavior: 'smooth',
                block: 'center',
                inline: 'nearest'
            } );
            itemList[itemIndex].classList.add( 'keyboard-selected-result' );
            itemIndex++;
        }
        //ARROW UP will move through the list UPWARDS
        else if( itemList[itemIndex - 1] && key === 38 && itemIndex >= 2 )
        {
            itemIndex--;
            if( itemList[itemIndex] )
            {
                itemList[itemIndex].classList.remove( 'keyboard-selected-result' );
            }

            itemList[itemIndex].scrollIntoView( {
                behavior: 'smooth',
                block: 'center',
                inline: 'nearest'
            } );
            itemList[itemIndex - 1].classList.add( 'keyboard-selected-result' );
        }
        // ENTER KEY will navigate to selected item's URL
        else if( key === 13 )
        {
            // But firs detect if Enter is pressed on an actual item in list!
            const item = itemList[itemIndex - 1];
            if( !item )
            {
                return false;
            }

            showSpinnerOnSearchNavigationAndClearSearchBar();
            const url = item.getElementsByTagName( 'a' )[0].href;
            navigateToUrl( url );
        }
    } );

    if( window.innerWidth < 768 )
    {
        window.onresize = checkDimensions;
    }
}

function showSpinnerOnSearchNavigationAndClearSearchBar()
{
    setDefaultSearchBarData();

    document.getElementById( 'searchListItem' ).innerHTML = '<div id="spinner_search_location"></div>';
    document.getElementById( 'searchListSuggestions' ).style.display = 'none';
    toggleSpinner( 'spinner_search_location', true );
}

function checkDimensions()
{
    document.getElementById( 'results-container' ).style.maxHeight = window.outerHeight - 175 + 'px';
    document.getElementById( 'searchListSuggestionItems' ).style.maxHeight = window.outerHeight - 262 + 'px';
    document.getElementById( 'popup-close-search' ).style.top = window.innerHeight - 95 + 'px';
}

function positionMobileCloseButtonOnSearchPopup()
{
    document.getElementById( 'popup-close-search' ).style.top = window.innerHeight - 95 + 'px';
    document.getElementById( 'searchListSuggestionItems' ).style.maxHeight = window.outerHeight - 262 + 'px';

    window.onresize = checkDimensions;
}

// function waitForElm( selector )
// {
//     return new Promise( function( resolve )
//     {
//         if( document.querySelector( selector ) )
//         {
//             return resolve( document.querySelector( selector ) );
//         }
//
//         const observer = new MutationObserver( function( mutations )
//         {
//             if( document.querySelector( selector ) )
//             {
//                 resolve( document.querySelector( selector ) );
//                 observer.disconnect();
//             }
//         } );
//
//         observer.observe( document.body, {
//             childList: true,
//             subtree: true
//         } );
//     } );
// }

export function handleCounter( minValue, maxValue )
{
    const plusMinus = document.querySelectorAll( '[data-plus-minus]' );
    plusMinus.forEach( function ( element )
    {
        handleCounterClasses( element.dataset.plusMinus, minValue, maxValue );
        handlePlusMinusInput( element, minValue, maxValue );
    } );
}

function handlePlusMinusInput( element, minValue, maxValue )
{
    const minusButtons = element.getElementsByClassName( 'decrease ' );
    minusButtons[0].addEventListener( 'click', function( event )
    {
        document.getElementById( element.dataset.plusMinus ).stepDown();
    } );

    const plusButtons = element.getElementsByClassName( 'increase ' );
    plusButtons[0].addEventListener( 'click', function( event )
    {
        document.getElementById( element.dataset.plusMinus ).stepUp();
    } );

    element.addEventListener( 'click', function( event )
    {
        handleCounterClasses( element.dataset.plusMinus, minValue, maxValue );
    } );
}

function handleCounterClasses( id, minValue, maxValue )
{
    const excursionCard = document.querySelector( '[data-id=' + '\'' + id + '\'' + ']' );
    const inputValue = document.getElementById( id ).value;
    const inputContainer = document.getElementById( 'plus-minus-container-' + id );

    if( inputValue > minValue )
    {
        if( excursionCard )
        {
            excursionCard.classList.add( 'card-selected' );
        }

        inputContainer.classList.remove( 'nr-disabled', 'minus-disabled' );
        inputContainer.getElementsByClassName( 'decrease' )[0].style.pointerEvents = 'auto';
    }
    else
    {
        if( excursionCard )
        {
            excursionCard.classList.remove( 'card-selected' );
        }

        inputContainer.classList.add( 'nr-disabled', 'minus-disabled' );
        inputContainer.getElementsByClassName( 'decrease' )[0].style.pointerEvents = 'none';
    }

    if( inputValue >= maxValue )
    {
        inputContainer.classList.add( 'plus-disabled' );
        inputContainer.getElementsByClassName( 'increase' )[0].style.pointerEvents = 'none';
    }
    else
    {
        inputContainer.classList.remove( 'plus-disabled' );
        inputContainer.getElementsByClassName( 'increase' )[0].style.pointerEvents = 'auto';
    }
}

export function selectCustomSelectorDropdownInForm( selectedElement, destinationInputId, parent, toggleClassName )
{
    const destinationInput = document.getElementById( destinationInputId );
    destinationInput.value = selectedElement.textContent;
    destinationInput.setAttribute( 'data-custom-selector-code', selectedElement.dataset.customSelectorSelectedOption );
    setDestinationPreference( selectedElement.textContent );
    toggleClass( document.getElementById( parent ), toggleClassName );
}

// function navigateToUrlAndSetStartDate( url, date )
// {
//     const searchBarCookie = {};
//     searchBarCookie.date = date;
//     setCookie( 'dw_sbd', JSON.stringify( searchBarCookie ) );
//
//     window.location = url;
// }

function navigateToUrlAndSetSearchBarData( url, startDate, duration, priceType3Code, priceType3Label, nightsAtAccommodation, durationWithFlight, cruiseOnlyPrice, cataloguePrice, cruiseAndFlightPrice )
{
    setSearchBarData( startDate, duration, priceType3Code, priceType3Label, nightsAtAccommodation, durationWithFlight, false, cruiseOnlyPrice, cataloguePrice, cruiseAndFlightPrice );
    navigateToUrl( url, false, true );
}

export function setSearchBarData( startDate, duration, priceType3Code, priceType3Label, nightsAtAccommodation, durationWithFlight, showDateOnSearchbar, cruiseOnlyPrice, cataloguePrice, cruiseAndFlightPrice )
{
    let searchBarData = getDefaultSearchBarObj();
    const searchBarCookie = getCookie( 'dw_sbd' );
    if( searchBarCookie )
    {
        searchBarData = JSON.parse( searchBarCookie );
    }

    if( startDate )
    {
        searchBarData.date = startDate;
    }

    if( duration )
    {
        //get duration
        const durationEl = document.querySelector( 'input[type="radio"][name="searchBarDuration"][value="' + duration + '"]' );
        if( durationEl )
        {
            searchBarData.duration = {
                label: durationEl.dataset.label,
                value: durationEl.value
            };
        }
        else
        {
            searchBarData.duration = {
                label: null,
                value: duration
            };
        }
    }

    if( nightsAtAccommodation )
    {
        searchBarData.nightsAtAccommodation = nightsAtAccommodation;
    }

    if( priceType3Code && priceType3Label )
    {
        searchBarData.priceType3 = {
            label: priceType3Label,
            value: priceType3Code
        };
    }

    if( durationWithFlight )
    {
        searchBarData.durationWithFlight = durationWithFlight;
    }

    if( !showDateOnSearchbar )
    {
        searchBarData.showDateOnSearchbar = false;
    }

    searchBarData.liveaboardPrice = {};

    if( cruiseOnlyPrice )
    {
        searchBarData.liveaboardPrice.cruiseOnlyPrice = encodeURIComponent( cruiseOnlyPrice );
    }

    if( cataloguePrice )
    {
        searchBarData.liveaboardPrice.cataloguePrice = encodeURIComponent( cataloguePrice );
    }

    if( cruiseAndFlightPrice )
    {
        searchBarData.liveaboardPrice.cruiseAndFlightPrice = encodeURIComponent( cruiseAndFlightPrice );
    }

    setCookie( "dw_sbd", JSON.stringify( searchBarData ) );
}

function handleCustomSelector( el, multiSelect )
{
    const defaultText = el.dataset.defaultText;
    const hiddenField = el.dataset.hiddenField;
    const inputTextEl = document.getElementById( el.dataset.selectionInput );
    const hiddenFieldInput = document.getElementById( hiddenField );
    const selectedOptions = [];
    const selectedValues = [];

    if( multiSelect )
    {
        const selected = el.querySelectorAll( 'input[type="checkbox"]:checked' );
        for( let i = 0; i < selected.length; i++ )
        {
            selectedOptions.push( selected[i].dataset.optionName );
            selectedValues.push( selected[i].value );
        }
    }
    else
    {
        const selected = el.querySelector( 'input[type="radio"]' );
        selectedOptions.push( selected.dataset.optionName );
        selectedValues.push( selected.value );
        selected.checked = true;
    }

    if( selectedOptions.length > 0 )
    {
        inputTextEl.style.color = '#003b5a';
        inputTextEl.innerHTML = selectedOptions.join( ', ' );
        const values = selectedValues.toString();
        hiddenFieldInput.setAttribute( 'value', values );
        inputTextEl.parentElement.parentElement.classList.add( 'input-group-valid' );
    }
    else
    {
        inputTextEl.style.color = '#506265';
        inputTextEl.innerHTML = defaultText;
        hiddenFieldInput.setAttribute( 'value', '' );
        inputTextEl.parentElement.parentElement.classList.remove( 'input-group-valid' );
    }

    if( !multiSelect )
    {
        closeCustomSelectPopup();
    }

    // Create an input (change) event.
    const event = new Event( 'change' );
    // Dispatch it.
    hiddenFieldInput.dispatchEvent( event );
}

export function handleCustomSelectorPreselect()
{
    const customSelectors = document.querySelectorAll( '[id$=\'-display\']' );
    for( let i = 0; i < customSelectors.length; i++ )
    {
        const selectedOptions = customSelectors[i].parentElement.querySelectorAll( 'input:checked' );
        const hiddenFieldInput = customSelectors[i].parentElement.querySelector( '.hidden-input-field' );

        const selectedValues = [];
        const preselectedOptions = [];
        for( let j = 0; j < selectedOptions.length; j++ )
        {
            preselectedOptions.push( selectedOptions[j].dataset.optionName );
            selectedValues.push( selectedOptions[j].value );
        }

        if( preselectedOptions.length > 0 )
        {
            customSelectors[i].style.color = '#003b5a';
            customSelectors[i].innerHTML = preselectedOptions.join( ', ' );
            const values = selectedValues.toString();
            hiddenFieldInput.setAttribute( 'value', values );
            customSelectors[i].parentElement.parentElement.classList.add( 'input-group-valid' );

            // Create an input (change) event.
            const event = new Event( 'change' );
            // Dispatch it.
            hiddenFieldInput.dispatchEvent( event );
        }
    }
}

export function elementHasValue( element )
{
    return !!( element && element.value );
}

export function allElementsHaveValues( arrayOfElements )
{
    return arrayOfElements.every( elementHasValue );
}

function radioIsValid( radioContainer )
{
    const validRadio = radioContainer.querySelector( 'input[type="radio"]:checked' );
    return !!validRadio;
}

export function allRadiosAreValid( radioArray )
{
    return radioArray.every( radioIsValid );
}

function checkboxIsValid( checkbox )
{
    return checkbox.checked === true;
}

export function allCheckboxesAreValid( checkboxArray )
{
    return checkboxArray.every( checkboxIsValid );
}
function formIsValid( form )
{
    return !!( form && form.dataset.formValid && form.dataset.formValid === 'true' );
}

export function allFormsAreValid( formArray )
{
    return formArray.every( formIsValid );
}

export function setInputFieldsInvalid( requiredFields, multiselect, multiselectHiddenFieldId )
{
    requiredFields.forEach( function( field )
    {
        if( !elementHasValue( field ) )
        {
            if( multiselect && multiselectHiddenFieldId && field.id.startsWith( multiselectHiddenFieldId ) )
            {
                multiselect.classList.add( 'input-group-invalid' );
                multiselect.classList.remove( 'input-group-valid' );
            }
            else
            {
                field.parentElement.classList.add( 'input-invalid' );
            }
        }
    } );
}

// function validateForms( elements )
// {
//     elements.forEach( function( element )
//     {
//         element.addEventListener("change", function( event)
//         {
//             if( !elementHasValue( event.target ) )
//             {
//                 event.target.parentElement.classList.add( 'input-invalid' );
//                 return;
//             }
//             if( event.target.parentElement.classList.contains( 'input-invalid' ) )
//             {
//                 event.target.parentElement.classList.remove( 'input-invalid' );
//             }
//             var form = document.getElementById( event.target.form.id );
//             var requiredElements = form.querySelectorAll('[required]' ); //only check elements on current form
//             var requiredElementsArray = Array.prototype.slice.call( requiredElements )
//             if( allElementsHaveValues( requiredElementsArray ) )
//             {
//                 var nextButton = form.querySelector('button[data-button="next"]' );
//                 nextButton.disabled = false;
//             }
//         })
//     })
// }

function showBookingOverview()
{
    const bookingOverview = document.getElementById( 'booking-detail-container' );
    const pageMask = document.getElementById( 'page-mask' );
    toggleClass( bookingOverview, 'booking-detail-open' );

    const body = document.getElementsByTagName( 'body' )[0];
    bookingOverview.querySelector( '#overview-details' ).style.zIndex = 40;
    bookingOverview.classList.contains( 'booking-detail-open' ) ? body.classList.add( 'body-overflow' ) : body.classList.remove( 'body-overflow' );
    bookingOverview.classList.contains( 'booking-detail-open' ) ? pageMask.style.display = 'block' : pageMask.style.display = 'none';
}

export function reloadPageOnBackNavigation( pageType )
{
    window.addEventListener( 'pageshow', function ( event )
    {
        if( event.persisted )
        {
            if( pageType === 'liveaboard' )
            {
                document.getElementById( 'startingDatesWrapper' ).innerHTML = '';
                toggleSpinner( 'starting-date-spinner' );
            }

            location.reload();
        }
    } );
}

async function loadStartingDatesDetails ( priceId, ppcPrice )
{
    const response = await apos.http.post( '/loadStartingDatesDetails', { body: { ppcPrice: ppcPrice } } );
    if( response.status === 'OK' )
    {
        if( response.data && response.data.details )
        {
            const detailsEl = document.getElementById( priceId + '_details' );
            detailsEl.innerHTML = response.data.details;
            detailsEl.setAttribute( 'data-loaded', true );

            initializeSliders();
        }
    }
    else
    {
        console.error( response );
    }
}

function clearFiltersAndReloadPage()
{
    const clearFilter = document.getElementById( 'clear-all-filters-wrapper' );
    clearFilter.classList.remove( 'show-clear-filter' );
    clearFilter.style.pointerEvents = 'none';

    setDefaultSearchBarData();

    // If "dw_cprod" is set in sessionStorage, remove it, so it resets the selected destination in offer popup
    if( sessionStorage.getItem( 'dw_cprod' ) )
    {
        sessionStorage.removeItem( 'dw_cprod' );
    }

    //Clear all sorting options when clearing filters
    deleteCookie( 'dw_sort' );

    const pagesArray = [ 'duikvakanties', 'duikresorts', 'liveaboards', 'aanbiedingen' ];
    const pathnameArray = window.location.pathname.replace( /^\/|\/$/g, '' ).split( '/' );
    const listingPage = pagesArray.find( function( page )
    {
        return page === pathnameArray[0];
    } );

    // pathname needs to be less then 3 to make sure that on liveaboard details page, it only reloads the page.
    // liveaboard details page structure is /liveaboards/liveaboards-in-azore/boat, the first path is the same as on
    // liveaboard listing and would navigate you away to initial path
    if( listingPage && pathnameArray.length < 3 )
    {
        window.location = window.location.origin + '/' + 'duikvakanties' + '/';
    }
    else
    {
        window.location.reload();
    }
}

export function setMessageSession( status, message, parentId )
{
    sessionStorage.setItem( 'reloading', 'true' );
    sessionStorage.setItem( 'status', status );
    sessionStorage.setItem( 'message', message );
    parentId = parentId || 'navbar-container';
    sessionStorage.setItem( 'parentId', parentId );
}

export function showMessage( status, message, parentId, time )
{
    const submitMessage = document.getElementById( 'submitMessage' );
    if( submitMessage )
    {
        submitMessage.remove();
    }

    const messageEl = document.createElement( 'div' );
    messageEl.id = 'submitMessage';
    const messageType = status === 'OK' ? 'success' : 'error';
    messageEl.classList.add( 'message', messageType );
    messageEl.innerHTML = message;

    parentId = parentId || 'navbar-container';
    const parentEl = document.getElementById( parentId );
    parentEl.after( messageEl );

    time = time || 5000;
    setTimeout( function()
    {
        messageEl.remove();
    }, time );
}

export function enableBackNavigationForBookingOnMobile()
{
    if( window.innerWidth < 1200 )
    {
        const btnBookingBack = document.getElementById( 'btn-previous-step' );
        toggleClass( btnBookingBack, 'disable-color' );
        btnBookingBack.disabled = false;
    }
}

// function stripTags( string )
// {
//     return string.replace( /(<([^>]+)>)/gi, '' );
// }

export function showErrorMessage( status, message, time )
{
    //Create the error container
    const errorContainer = document.createElement( 'div' );
    errorContainer.className = 'error-msg-container';

    // Create the error-wrapper div
    const errorWrapper = document.createElement( 'div' );
    errorWrapper.id = 'error-wrapper';

    // Create the error-title div
    const errorTitle = document.createElement( 'div' );
    errorTitle.id = 'error-title';
    errorTitle.className = 'h5-style error-title';
    errorTitle.textContent = message.title;

    // Create the error-message div
    const errorMessage = document.createElement( 'div' );
    errorMessage.id = 'error-text';
    errorMessage.className = 'h5-style error-text';
    errorMessage.textContent = message.text;

    // Append errorTitle and errorMessage to errorWrapper
    errorWrapper.appendChild( errorTitle );
    errorWrapper.appendChild( errorMessage );

    // Append errorWrapper to the errorContainer
    errorContainer.appendChild( errorWrapper );

    // Append errorContainer to the body
    document.body.appendChild( errorContainer );

    const errorContainers = document.querySelectorAll( '.error-msg-container' );
    if( window.innerWidth > 768 )
    {
        for( let i = 0; i < errorContainers.length; i++ )
        {
            if( errorContainers[i - 1] )
            {
                const lastErrorMessage = errorContainers[i - 1].getBoundingClientRect();
                errorContainers[i].style.top = lastErrorMessage.y - errorContainers[i - 1].offsetHeight - 10 + 'px';
            }

        }
    }

    // toggleClass with timeout to create smooth transition when showing the error message
    setTimeout( function()
    {
        toggleClass( errorContainer, 'show' );
    }, 100 );

    time = time || 5000;
    setTimeout( function()
    {
        removeErrorMessage( errorContainer );
    }, time );
}

function removeErrorMessage( errorWrapper )
{
    errorWrapper.remove();
}

export function handleOpenStartingDatesDetails( el, priceId, ppcPrices )
{
    toggleExtraInfo( el, el.parentElement, 'product-info-open', true );
    const detailsEl = document.getElementById( priceId + '_details' );
    if( detailsEl && detailsEl.getAttribute( 'data-loaded' ) === 'false' )
    {
        toggleSpinner( el.dataset.spinnerId );
        const ppcPrice = ppcPrices.find( function( item )
        {
            return item.id === parseInt( priceId );
        } );
        loadStartingDatesDetails( priceId, ppcPrice );
    }
};

async function submitPersonalTravelAdvice( recaptchaResponse )
{
    // get HTML elements
    const form = document.getElementById( 'personal-travel-advice-form' );
    const button = form.querySelector( 'button[id="button-request-personal-travel-advice"]' );
    button.classList.add( 'disable-submit' );
    const personalName = form.querySelector( 'input[name="personalName"]' );
    const familyName = form.querySelector( 'input[name="familyName"]' );
    const email = form.querySelector( 'input[name="email"]' );
    const phone = form.querySelector( 'input[name="phoneNumber"]' );
    const city = form.querySelector( 'input[name="city"]' );
    const country = form.querySelector( 'select[name="country"]' );
    const startDate = form.querySelector( 'input[name="startDate"]' );
    const endDate = form.querySelector( 'input[name="endDate"]' );
    const duration = form.querySelector( 'input[name="duration"]' );
    const vacationPeriod = form.querySelector( 'input[name="vacationPeriod"]:checked' );
    const numberOfAdults = form.querySelector( 'input[name="numberOfAdults"]' );
    const numberOfChildren = form.querySelector( 'input[name="numberOfChildren"]' );
    const childrenAges = form.querySelectorAll( 'input[name="childAge"]' );
    const budget = form.querySelector( 'input[name="budget"]' );
    const inclusiveOrExclusive = form.querySelector( 'input[name="inclusiveOrExclusive"]:checked' ); // radio
    const extraInformation = form.querySelector( 'textarea[name="extra-information"]' );
    const destination = form.querySelector( 'input[name="destination"]' );
    const visitedDestinations = form.querySelector( 'textarea[name="visited-destination"]' );
    const personalTravelAdviceOptions = form.querySelectorAll( 'input[name^="advice"]:checked' );
    const moreInformation = form.querySelectorAll( 'input[name^="more-information"]' );
    const newsletter = form.querySelector( 'input[name="receiveNewsletter"]:checked' );

    // categorize element values ( if they exist ) for laundering
    const rawStrings = {};
    rawStrings.personalName = personalName && personalName.value ? personalName.value : null;
    rawStrings.familyName = familyName && familyName.value ? familyName.value : null;
    rawStrings.phoneNumber = phone && phone.value ? phone.value : null;
    rawStrings.city = city && city.value ? city.value : null;
    rawStrings.duration = duration && duration.value ? duration.value : null;
    rawStrings.vacationPeriod = vacationPeriod && vacationPeriod.value ? vacationPeriod.value : 'false';
    rawStrings.numberOfAdults = numberOfAdults && numberOfAdults.value ? numberOfAdults.value : null;
    rawStrings.numberOfChildren = numberOfChildren && numberOfChildren.value ? numberOfChildren.value : null;
    if( childrenAges && childrenAges.length > 0 )
    {
        const childrenMap = new Map();

        for( let i = 0; i < childrenAges.length; i++ )
        {
            const childLabel = i + 1;
            const childAge = childrenAges[i] && childrenAges[i].value ? childrenAges[i].value : null;
            childrenMap.set( 'ageOfChild_' + childLabel, childAge );
        }

        const childrenWithAge = Object.fromEntries( childrenMap );
        Object.assign( rawStrings, childrenWithAge );
    }
    rawStrings.budget = budget && budget.value ? budget.value : null;
    rawStrings.inclusiveOrExclusive = inclusiveOrExclusive && inclusiveOrExclusive.value ? inclusiveOrExclusive.value : 'false';
    rawStrings.extraInformation = extraInformation && extraInformation.value ? extraInformation.value : null;
    rawStrings.destination = destination && destination.value ? destination.value : null;
    rawStrings.visitedDestinations = visitedDestinations && visitedDestinations.value ? visitedDestinations.value : null;
    rawStrings.country = country && country.value ? country.value : null;
    if( personalTravelAdviceOptions && personalTravelAdviceOptions.length > 0 )
    {
        const personalTravelAdviceOptionsMap = new Map();

        for( let i = 0; i < personalTravelAdviceOptions.length; i++ )
        {
            const optionLabel = i + 1;
            const optionValue = personalTravelAdviceOptions[i] && personalTravelAdviceOptions[i].value ? personalTravelAdviceOptions[i].dataset.adviceTitle + '/' + personalTravelAdviceOptions[i].value : null;
            personalTravelAdviceOptionsMap.set( 'personalTravelAdviceOption_' + optionLabel, optionValue );

            // set label based off data attribute for data layer
            personalTravelAdviceOptionsMap.set( optionValue, personalTravelAdviceOptions[i].dataset.ptaLabel );
        }

        const personalTravelAdviceOptionsWithValues = Object.fromEntries( personalTravelAdviceOptionsMap );
        Object.assign( rawStrings, personalTravelAdviceOptionsWithValues );
    }
    if( moreInformation && moreInformation.length > 0 )
    {
        const moreInformationMap = new Map();

        for( let i = 0; i < moreInformation.length; i++ )
        {
            const moreInformationLabel = i + 1;
            const moreInformationValue = moreInformation[i] && moreInformation[i].value ? moreInformation[i].dataset.moreInformationAdviceTitle + '/' + moreInformation[i].value : null;
            moreInformationMap.set( 'moreInformation_' + moreInformationLabel, moreInformationValue );
        }

        const moreInformationWithValues = Object.fromEntries( moreInformationMap );
        Object.assign( rawStrings, moreInformationWithValues );
    }
    // todo: consider creating launder for boolean values
    rawStrings.newsletter = newsletter && newsletter.value ? newsletter.value : null;

    const rawEmails = {};
    rawEmails.email = email && email.value ? email.value : null;

    const rawDates = {};
    rawDates.startDate = startDate && startDate.value ? startDate.value : null;
    rawDates.endDate = endDate && endDate.value ? endDate.value : null;

    const formData = [
        {
            type: 'string',
            value: rawStrings
        },
        {
            type: 'email',
            value: rawEmails
        },
        {
            type: 'date',
            value: rawDates
        }
    ];

    // launder values
    const response = await apos.http.post( '/launderData', { body: formData } );
    if( response.status === 'OK' && response.data )
    {
        // handle errors
        let errorCounter = 0;
        if( !response.data.country )
        {
            scrollToElementAndAddClass( country.parentElement, 'input-invalid', form.parentElement.parentElement );
            errorCounter++;
        }
        if( !response.data.phoneNumber )
        {
            scrollToElementAndAddClass( phone.parentElement, 'input-invalid', form.parentElement.parentElement );
            errorCounter++;
        }
        if( !response.data.email )
        {
            scrollToElementAndAddClass( email.parentElement, 'input-invalid', form.parentElement.parentElement );
            errorCounter++;
        }
        if( !response.data.familyName )
        {
            scrollToElementAndAddClass( familyName.parentElement, 'input-invalid', form.parentElement.parentElement );
            errorCounter++;
        }
        if( !response.data.personalName )
        {
            scrollToElementAndAddClass( personalName.parentElement, 'input-invalid', form.parentElement.parentElement );
            errorCounter++;
        }
        if( errorCounter > 0 )
        {
            button.classList.remove( 'disable-submit' );
            return;
        }
        const newData = Object.assign( {}, response.data );
        // newData.recaptchaResponse = recaptchaResponse;
        const recaptchaPublicKey = button.getAttribute( 'data-sitekey' );
        grecaptcha.ready( function()
        {
            grecaptcha.execute( recaptchaPublicKey, { action: 'submit' } ).then( function( token )
            {
                const recaptchaResponse = token;
                newData.recaptchaResponse = recaptchaResponse;
                // create lead with data
                createLeadPersonalTravelAdvice( newData, button, form.id );
            } );
        } );
        // // create lead with data
        // createLeadPersonalTravelAdvice( newData, button, form.id );
    }
    else
    {
        console.log( '-> response', response );
    }
}

async function createTravelerFromTemplate()
{
    // Function that copies the first traveler to the container, while changing the traveler index in ids/names/labels
    const accordion = document.getElementById( 'accordion-travelers' );
    const container = document.getElementById( 'static-booking-travelers-container' );
    const template = document.getElementById( 'static-booking-traveler-1' );

    if( accordion && container && container.dataset.travelersCount && template )
    {
        // Increase the number of travelers ( only increase the dataset at the end of the function, when we actually add a traveler to the container )
        const numTravelers = parseInt( container.dataset.travelersCount ) + 1;
        // Create new template from traveler 1 HTML, while replacing traveler number and remove event listeners from the initial template
        const newTemplate = template.innerHTML.replace( /1/g, numTravelers );
        // Create new section element, and add the new template to it, together with the new id
        const newTraveler = document.createElement( 'section' );
        newTraveler.innerHTML = newTemplate;
        newTraveler.setAttribute( 'id', 'static-booking-traveler-' + numTravelers );
        // Append the modified newTraveler to the container
        container.appendChild( newTraveler );
        // Increase the number of travelers in the dataset
        container.dataset.travelersCount = numTravelers;
        // Check if remove button exists, if not, add it to the container
        let removeButton = accordion.querySelector( 'button[id="static-booking-remove-traveler-button"]' );
        if( !removeButton )
        {
            removeButton = document.createElement( 'button' );
            removeButton.setAttribute( 'id', 'static-booking-remove-traveler-button' );
            removeButton.setAttribute( 'class', 'btn btn-light-bg' );
            removeButton.setAttribute( 'type', 'button' );
            removeButton.onclick = function ()
            {
                removeLastStaticBookingTraveler();
            };
            removeButton.innerHTML = await getTranslatedString( 'remove' );
            container.insertAdjacentElement( 'afterend', removeButton );
        }

        const currentTravelerContainerId = '#static-booking-traveler-' + numTravelers;
        const travelerContainer = accordion.querySelector( currentTravelerContainerId );
        if( travelerContainer )
        {
            resetLitePickersInContainer( travelerContainer );
        }

        const existingTravelerTemplate = document.querySelector( '.initial-traveler' );
        existingTravelerTemplate.dataset.isSingleTraveler = 'false';

        //initialize datepickers
        initializeDatePickers();
        initializeBirthDatePickers();

        const removeTemplateButton = accordion.querySelector( 'button[id="static-booking-remove-traveler-template-button"]' );
        if( removeTemplateButton )
        {
            removeTemplateButton.remove();
        }
    }
}

function resetLitePickersInContainer( container )
{
    const birthDatePicker = container.querySelectorAll( '.birth-date-picker' );
    if( birthDatePicker )
    {
        birthDatePicker.forEach( function( element )
        {
            element.removeAttribute( 'date-initialized' );
        } );
    }

    const datePicker = container.querySelectorAll( '.date-picker' );
    if( datePicker )
    {
        datePicker.forEach( function( element )
        {
            element.removeAttribute( 'date-initialized' );
        } );
    }
}

function removeLastStaticBookingTraveler()
{
    const accordion = document.getElementById( 'accordion-travelers' );
    const container = document.getElementById( 'static-booking-travelers-container' );
    if( accordion && container && container.dataset.travelersCount )
    {
        const numTravelers = parseInt( container.dataset.travelersCount );
        if( numTravelers > 1 )
        {
            const lastTraveler = document.getElementById( 'static-booking-traveler-' + numTravelers );
            lastTraveler.remove();
            container.dataset.travelersCount = numTravelers - 1;
        }
        if( numTravelers === 2 )
        {
            const removeButton = accordion.querySelector( 'button[id="static-booking-remove-traveler-button"]' );
            removeButton.remove();

            const existingTravelerTemplate = document.querySelector( '.initial-traveler' );
            existingTravelerTemplate.dataset.isSingleTraveler = 'true';
        }
    }
}

async function submitStaticBooking( recaptchaResponse )
{
    // get input fields and their values ( custom function for getting travelers )
    const form = document.getElementById( 'static-booking-form' );
    const button = form.querySelector( 'button[id="button-request-static-booking"]' );
    button.classList.add( 'disable-submit' );

    // check if recaptchaResponse exists
    // if( !recaptchaResponse )
    // {
    //     return;
    //     button.classList.remove( 'disable-submit' );
    // }
    // check if all required customCheckboxes are checked and create array of all customCheckboxes with their values to be saved in lead
    const checkboxContainer = document.getElementById( 'static-booking-confirmation-checkbox-container' );
    const customCheckboxesArray = [];
    if( checkboxContainer )
    {
        const customCheckboxes = checkboxContainer.querySelectorAll( 'input[type="checkbox"]' );
        for( let i = 0; i < customCheckboxes.length; i++ )
        {
            const checkbox = customCheckboxes[i];
            if( checkbox.hasAttribute( 'required' ) && !checkbox.checked )
            {
                const accordion = form.querySelector( 'div[id="accordion-confirmation"]' );
                // Check if the accordion does not have the class 'accordion-open'
                if( !accordion.classList.contains( 'accordion-open' ) )
                {
                    // Add the class 'accordion-open' so that we can scroll to the input field
                    accordion.classList.add( 'accordion-open' );
                }
                scrollToElementAndAddClass( checkbox.parentElement.parentElement, 'input-invalid', checkboxContainer );
                button.classList.remove( 'disable-submit' );
                return;
            }
            const checkboxObj = {};
            checkboxObj.name = checkbox.name;
            checkboxObj.type = checkbox.dataset.checkboxType ? checkbox.dataset.checkboxType : 'checkbox';
            checkboxObj.checked = checkbox.checked;
            customCheckboxesArray.push( checkboxObj );
        }
    }

    // Main booker
    const salutation = form.querySelector( 'input[name="main-booker-gender"]:checked' );
    const personalName = form.querySelector( 'input[name="main-booker-personal-name"]' );
    const familyName = form.querySelector( 'input[name="main-booker-family-name"]' );
    const address = form.querySelector( 'input[name="main-booker-address"]' );
    const postalCode = form.querySelector( 'input[name="main-booker-post-code"]' );
    const city = form.querySelector( 'input[name="main-booker-city"]' );
    const country = form.querySelector( 'select[name="main-booker-country"]' );
    const destination = form.querySelector( 'input[name="main-booker-destination"]' );
    const startDate = form.querySelector( 'input[name="earliestDepartureDate"]' );
    const endDate = form.querySelector( 'input[name="lastestDepartureDate"]' );
    const email = form.querySelector( 'input[name="main-booker-email"]' );
    const phoneNr = form.querySelector( 'input[name="main-booker-phone-nr"]' );
    const alternativeNr = form.querySelector( 'input[name="main-booker-alternative-nr"]' );
    const emergencyContact = form.querySelector( 'input[name="main-booker-emergency-contact"]' );
    // Offer
    const offer = form.querySelector( 'textarea[name="static-booking-offer"]' );

    const rawStrings = {};
    const rawEmails = {};
    const rawDates = {};
    rawStrings.salutation = salutation && salutation.value ? salutation.value : null;
    rawStrings.personalName = personalName && personalName.value ? personalName.value : null;
    rawStrings.familyName = familyName && familyName.value ? familyName.value : null;
    rawStrings.address = address && address.value ? address.value : null;
    rawStrings.postalCode = postalCode && postalCode.value ? postalCode.value : null;
    rawStrings.city = city && city.value ? city.value : null;
    rawStrings.country = country && country.value ? country.value : null;
    rawStrings.phoneNumber = phoneNr && phoneNr.value ? phoneNr.value : null;
    rawStrings.alternativeNumber = alternativeNr && alternativeNr.value ? alternativeNr.value : null;
    rawStrings.emergencyContact = emergencyContact && emergencyContact.value ? emergencyContact.value : null;
    rawStrings.offer = offer && offer.value ? offer.value : 'false';
    rawStrings.destination = destination && destination.value ? destination.value : null;
    rawEmails.email = email && email.value ? email.value : null;

    rawDates.startDate = startDate && startDate.value ? startDate.value : null;
    rawDates.endDate = endDate && endDate.value ? endDate.value : null;

    const travelersObject = getStaticBookingTravelerValues();
    const travelerValues = Object.fromEntries( travelersObject );

    for( const key in travelerValues )
    {
        if( travelerValues.hasOwnProperty( key ) )
        {
            if( key.endsWith( '_email' ) )
            {
                rawEmails[key] = travelerValues[key];
            }
            else if( key.endsWith( 'Date' ) )
            {
                rawDates[key] = travelerValues[key];
            }
            else
            {
                rawStrings[key] = travelerValues[key];
            }
        }
    }

    const formData = [
        {
            type: 'string',
            value: rawStrings
        },
        {
            type: 'email',
            value: rawEmails
        },
        {
            type: 'date',
            value: rawDates
        }
    ];

    // launder values
    const response = await apos.http.post( '/launderData', { body: formData } );
    if( response.status === 'OK' && response.data )
    {
        // handle errors
        let errorCounter = 0;
        // we always have at least 1 traveler, this is just added security
        if( !response.data.numberOfTravelers )
        {
            const accordion = form.querySelector( 'div[id="accordion-travelers"]' );
            if( !accordion.classList.contains( 'accordion-open' ) )
            {
                accordion.classList.add( 'accordion-open' );
            }
            errorCounter++;
        }
        else
        {
            const accordion = form.querySelector( 'div[id="accordion-travelers"]' );
            const number = parseInt( response.data.numberOfTravelers );
            if( !number || isNaN( number ) )
            {
                if( !accordion.classList.contains( 'accordion-open' ) )
                {
                    accordion.classList.add( 'accordion-open' );
                }
                errorCounter++;
            }
            // For each traveler, check if all required fields are filled in
            const existingTravelerTemplate = document.querySelector( '.initial-traveler' );
            if( existingTravelerTemplate.dataset.isSingleTraveler === 'false' )
            {
                for( let i = number; i >= 2; i-- )
                {
                    if( !response.data['traveler_' + i + '_birthDate'] )
                    {
                        if( !accordion.classList.contains( 'accordion-open' ) )
                        {
                            accordion.classList.add( 'accordion-open' );
                        }
                        const element = document.querySelector( 'input[name="birth-date-' + i + '"]' );
                        scrollElementIntoViewAndAddClass( element.parentElement, 'input-invalid', element.parentElement.parentElement );
                        errorCounter++;
                    }
                    if( !response.data['traveler_' + i + '_familyName'] )
                    {
                        if( !accordion.classList.contains( 'accordion-open' ) )
                        {
                            accordion.classList.add( 'accordion-open' );
                        }
                        const element = document.querySelector( 'input[name="family-name-' + i + '"]' );
                        scrollElementIntoViewAndAddClass( element.parentElement, 'input-invalid', element.parentElement.parentElement );
                        errorCounter++;
                    }
                    if( !response.data['traveler_' + i + '_personalName'] )
                    {
                        if( !accordion.classList.contains( 'accordion-open' ) )
                        {
                            accordion.classList.add( 'accordion-open' );
                        }
                        const element = document.querySelector( 'input[name="personal-name-' + i + '"]' );
                        scrollElementIntoViewAndAddClass( element.parentElement, 'input-invalid', element.parentElement.parentElement );
                        errorCounter++;
                    }
                    if( !response.data['traveler_' + i + '_salutation'] )
                    {
                        if( !accordion.classList.contains( 'accordion-open' ) )
                        {
                            accordion.classList.add( 'accordion-open' );
                        }
                        const element = document.querySelector( 'div[id="static-booking-genders-' + i + '"]' );
                        scrollElementIntoViewAndAddClass( element, 'input-invalid', element );
                        errorCounter++;
                    }
                }
            }
        }
        // check main booker required fields
        if( !response.data.emergencyContact )
        {
            scrollElementIntoViewAndAddClass( emergencyContact.parentElement, 'input-invalid', emergencyContact.parentElement.parentElement );
            errorCounter++;
        }
        if( !response.data.phoneNumber )
        {
            scrollElementIntoViewAndAddClass( phoneNr.parentElement, 'input-invalid', phoneNr.parentElement.parentElement );
            errorCounter++;
        }
        if( !response.data.email )
        {
            scrollElementIntoViewAndAddClass( email.parentElement, 'input-invalid', email.parentElement.parentElement );
            errorCounter++;
        }
        if( !response.data.country )
        {
            scrollElementIntoViewAndAddClass( country.parentElement, 'input-invalid', country.parentElement.parentElement );
            errorCounter++;
        }
        if( !response.data.startDate )
        {
            scrollElementIntoViewAndAddClass( startDate.parentElement, 'input-invalid', startDate.parentElement.parentElement );
            errorCounter++;
        }
        if( !response.data.endDate )
        {
            scrollElementIntoViewAndAddClass( endDate.parentElement, 'input-invalid', endDate.parentElement.parentElement );
            errorCounter++;
        }
        if( !response.data.city )
        {
            scrollElementIntoViewAndAddClass( city.parentElement, 'input-invalid', city.parentElement.parentElement );
            errorCounter++;
        }
        if( !response.data.postalCode )
        {
            scrollElementIntoViewAndAddClass( postalCode.parentElement, 'input-invalid', postalCode.parentElement.parentElement );
            errorCounter++;
        }
        if( !response.data.address )
        {
            scrollElementIntoViewAndAddClass( address.parentElement, 'input-invalid', address.parentElement.parentElement );
            errorCounter++;
        }
        if( !response.data.familyName )
        {
            scrollElementIntoViewAndAddClass( familyName.parentElement, 'input-invalid', familyName.parentElement.parentElement );
            errorCounter++;
        }
        if( !response.data.personalName )
        {
            scrollElementIntoViewAndAddClass( personalName.parentElement, 'input-invalid', personalName.parentElement.parentElement );
            errorCounter++;
        }
        if( !response.data.salutation )
        {
            const salutationsContainer = form.querySelector( 'div[id="static-booking-genders"]' );
            scrollElementIntoViewAndAddClass( salutationsContainer, 'input-invalid', salutationsContainer );
            errorCounter++;
        }
        if( errorCounter > 0 )
        {
            button.classList.remove( 'disable-submit' );
            return;
        }
        const newData = Object.assign( {}, response.data );
        newData.customCheckboxes = customCheckboxesArray;
        const recaptchaPublicKey = button.getAttribute( 'data-sitekey' );
        grecaptcha.ready( function()
        {
            grecaptcha.execute( recaptchaPublicKey, { action: 'submit' } ).then( function( token )
            {
                const recaptchaResponse = token;
                newData.recaptchaResponse = recaptchaResponse;
                createLeadStaticBooking( newData, button, form.id );
            } );
        } );
        // newData.recaptchaResponse = recaptchaResponse;
        // createLeadStaticBooking( newData, button, form.id );
    }
}

function getStaticBookingTravelerValues()
{
    const form = document.getElementById( 'static-booking-form' );
    const travelers = form.querySelectorAll( 'section[id^="static-booking-traveler-"]' );

    if( travelers && travelers.length > 0 )
    {
        var travelersMap = new Map();

        travelersMap.set( 'numberOfTravelers', travelers.length );

        // get main traveler
        const mainTraveler = 1;
        const mainTravelerSalutation = form.querySelector( 'input[name="main-booker-gender"]:checked' );
        const mainTravelerPersonalName = form.querySelector( 'input[name="main-booker-personal-name"]' );
        const mainTravelerFamilyName = form.querySelector( 'input[name="main-booker-family-name"]' );
        const mainTravelerBirthDate = form.querySelector( 'input[name="birth-date"]' );
        const mainTravelerCancellationInsurance = form.querySelector( 'input[name="main-booker-cancellation-insurance"]:checked' );
        const mainTravelerTravelInsurance = form.querySelector( 'input[name="main-booker-travel-insurance"]:checked' );
        const mainTravelerEmail = form.querySelector( 'input[name="main-booker-email"]' );
        const mainTravelerReceiveNewsletter = form.querySelector( 'input[name="main-booker-newsletter"]:checked' );
        const mainTravelerDivePackage = form.querySelector( 'textarea[name="main-booker-dive-package"]' );
        const mainTravelerDiveExperience = form.querySelector( 'textarea[name="main-booker-dive-experience"]' );
        const mainTravelerStartDate = form.querySelector( 'input[name="main-booker-start-date"]' );
        const mainTravelerRemarks = form.querySelector( 'textarea[name="main-booker-remarks"]' );

        const mainTravelerSalutationValue = mainTravelerSalutation && mainTravelerSalutation.value ? mainTravelerSalutation.value : null;
        const mainTravelerPersonalNameValue = mainTravelerPersonalName && mainTravelerPersonalName.value ? mainTravelerPersonalName.value : null;
        const mainTravelerFamilyNameValue = mainTravelerFamilyName && mainTravelerFamilyName.value ? mainTravelerFamilyName.value : null;
        const mainTravelerBirthDateValue = mainTravelerBirthDate && mainTravelerBirthDate.value ? mainTravelerBirthDate.value : null;
        const mainTravelerCancellationInsuranceValue = mainTravelerCancellationInsurance && mainTravelerCancellationInsurance.value ? mainTravelerCancellationInsurance.value : 'false';
        const mainTravelerTravelInsuranceValue = mainTravelerTravelInsurance && mainTravelerTravelInsurance.value ? mainTravelerTravelInsurance.value : 'false';
        const mainTravelerEmailValue = mainTravelerEmail && mainTravelerEmail.value ? mainTravelerEmail.value : null;
        const mainTravelerReceiveNewsletterValue = mainTravelerReceiveNewsletter && mainTravelerReceiveNewsletter.value ? mainTravelerReceiveNewsletter.value : 'false';
        const mainTravelerDivePackageValue = mainTravelerDivePackage && mainTravelerDivePackage.value ? mainTravelerDivePackage.value : null;
        const mainTravelerDiveExperienceValue = mainTravelerDiveExperience && mainTravelerDiveExperience.value ? mainTravelerDiveExperience.value : null;
        const mainTravelerStartDateValue = mainTravelerStartDate && mainTravelerStartDate.value ? mainTravelerStartDate.value : null;
        const mainTravelerRemarksValue = mainTravelerRemarks && mainTravelerRemarks.value ? mainTravelerRemarks.value : null;

        travelersMap.set( 'traveler_' + mainTraveler + '_salutation', mainTravelerSalutationValue );
        travelersMap.set( 'traveler_' + mainTraveler + '_personalName', mainTravelerPersonalNameValue );
        travelersMap.set( 'traveler_' + mainTraveler + '_familyName', mainTravelerFamilyNameValue );
        travelersMap.set( 'traveler_' + mainTraveler + '_birthDate', mainTravelerBirthDateValue );
        travelersMap.set( 'traveler_' + mainTraveler + '_cancellationInsurance', mainTravelerCancellationInsuranceValue );
        travelersMap.set( 'traveler_' + mainTraveler + '_travelInsurance', mainTravelerTravelInsuranceValue );
        travelersMap.set( 'traveler_' + mainTraveler + '_email', mainTravelerEmailValue );
        travelersMap.set( 'traveler_' + mainTraveler + '_receiveNewsletter', mainTravelerReceiveNewsletterValue );
        travelersMap.set( 'traveler_' + mainTraveler + '_divePackage', mainTravelerDivePackageValue );
        travelersMap.set( 'traveler_' + mainTraveler + '_diveExperience', mainTravelerDiveExperienceValue );
        travelersMap.set( 'traveler_' + mainTraveler + '_startDate', mainTravelerStartDateValue );
        travelersMap.set( 'traveler_' + mainTraveler + '_remarks', mainTravelerRemarksValue );

        // If there are more travelers other than the main traveler, get their values
        const existingTravelerTemplate = document.querySelector( '.initial-traveler' );
        if( existingTravelerTemplate.dataset.isSingleTraveler === 'false' )
        {
            // Travelers start from 1, because 0 is the main traveler which is already added
            for( let i = 1; i < travelers.length; i++ )
            {
                // And set its index to i + 1, so we get the correct id's from the template!
                const travelerIndex = i + 1;

                const travelerSalutation = travelers[i].querySelector( 'input[name="salutation-' + travelerIndex + '"]:checked' );
                const travelerPersonalName = travelers[i].querySelector( 'input[name="personal-name-' + travelerIndex + '"]' );
                const travelerFamilyName = travelers[i].querySelector( 'input[name="family-name-' + travelerIndex + '"]' );
                const travelerBirthDate = travelers[i].querySelector( 'input[name="birth-date-' + travelerIndex + '"]' );
                const travelerCancellationInsurance = travelers[i].querySelector( 'input[name="cancellation-insurance-' + travelerIndex + '"]:checked' );
                const travelerTravelInsurance = travelers[i].querySelector( 'input[name="travel-insurance-' + travelerIndex + '"]:checked' );
                const travelerEmail = travelers[i].querySelector( 'input[name="email-' + travelerIndex + '"]' );
                const travelerEmailCheckbox = travelers[i].querySelector( 'input[name="email-checkbox-' + travelerIndex + '"]:checked' );
                const travelerDivePackage = travelers[i].querySelector( 'textarea[name="dive-package-' + travelerIndex + '"]' );
                const travelerDiveExperience = travelers[i].querySelector( 'textarea[name="dive-experience-' + travelerIndex + '"]' );
                const travelerStartDate = travelers[i].querySelector( 'input[name="start-date-' + travelerIndex + '"]' );
                const travelerRemarks = travelers[i].querySelector( 'textarea[name="remarks-' + travelerIndex + '"]' );

                const salutation = travelerSalutation && travelerSalutation.value ? travelerSalutation.value : null;
                const personalName = travelerPersonalName && travelerPersonalName.value ? travelerPersonalName.value : null;
                const familyName = travelerFamilyName && travelerFamilyName.value ? travelerFamilyName.value : null;
                const birthDate = travelerBirthDate && travelerBirthDate.value ? travelerBirthDate.value : null;
                const cancellationInsurance = travelerCancellationInsurance && travelerCancellationInsurance.value ? travelerCancellationInsurance.value : 'false';
                const travelInsurance = travelerTravelInsurance && travelerTravelInsurance.value ? travelerTravelInsurance.value : 'false';
                const email = travelerEmail && travelerEmail.value ? travelerEmail.value : null;
                const receiveNewsletter = travelerEmailCheckbox && travelerEmailCheckbox.value ? travelerEmailCheckbox.value : 'false';
                const divePackage = travelerDivePackage && travelerDivePackage.value ? travelerDivePackage.value : null;
                const diveExperience = travelerDiveExperience && travelerDiveExperience.value ? travelerDiveExperience.value : null;
                const startDate = travelerStartDate && travelerStartDate.value ? travelerStartDate.value : null;
                const remarks = travelerRemarks && travelerRemarks.value ? travelerRemarks.value : null;

                travelersMap.set( 'traveler_' + travelerIndex + '_salutation', salutation );
                travelersMap.set( 'traveler_' + travelerIndex + '_personalName', personalName );
                travelersMap.set( 'traveler_' + travelerIndex + '_familyName', familyName );
                travelersMap.set( 'traveler_' + travelerIndex + '_birthDate', birthDate );
                travelersMap.set( 'traveler_' + travelerIndex + '_cancellationInsurance', cancellationInsurance );
                travelersMap.set( 'traveler_' + travelerIndex + '_travelInsurance', travelInsurance );
                travelersMap.set( 'traveler_' + travelerIndex + '_email', email );
                travelersMap.set( 'traveler_' + travelerIndex + '_receiveNewsletter', receiveNewsletter );
                travelersMap.set( 'traveler_' + travelerIndex + '_divePackage', divePackage );
                travelersMap.set( 'traveler_' + travelerIndex + '_diveExperience', diveExperience );
                travelersMap.set( 'traveler_' + travelerIndex + '_startDate', startDate );
                travelersMap.set( 'traveler_' + travelerIndex + '_remarks', remarks );
            }
        }
    }

    return travelersMap;
}

function toggleFooterLinks( elementId )
{
    const element = document.getElementById( elementId );
    if( element )
    {
        element.parentElement.classList.contains( 'open' ) ? element.parentElement.classList.remove( 'open' ) : element.parentElement.classList.add( 'open' );
    }
}

export function initializeLitePicker()
{
    const dates = getDates();

    if( document.getElementById( 'litepicker' ) )
    {
        const picker = new window.Litepicker( {
            element: document.getElementById( 'search-bar-litepicker-day-view' ),
            showWeekNumbers: true,
            inlineMode: true,
            lang: 'nl',
            minDate: dates.tomorrow,
            maxDate: dates.twoYears,
            buttonText: {
                previousMonth: '',
                nextMonth: ''
            }
        } );

        if( getCookie( 'dw_sbd' ) )
        {
            const parsedCookie = JSON.parse( getCookie( 'dw_sbd' ) );
            if( parsedCookie.date && parsedCookie.date !== '' )
            {
                picker.gotoDate( parsedCookie.date );
                picker.setDate( parsedCookie.date );
                document.getElementById( 'searchBarSelectedDate' ).value = parsedCookie.date;
            }
        }

        const litepickerYearSelector = document.querySelector( '.search-bar-litepicker-year-selector' );
        const litepickerMonthsList = document.querySelector( '.search-bar-litepicker-months-list' );

        litepickerMonthsList.addEventListener( 'click', function( event )
        {
            if( event.target.dataset.selectedMonth )
            {
                picker.gotoDate( litepickerYearSelector.dataset.selectedYear + '-' + event.target.dataset.selectedMonth + '-01' );
                switchTab( document.querySelector( '.litepicker-date' ), 'selected', 'search-bar-litepicker-day-view', 'litepicker-view-open' );
            }
        } );

        picker.on( 'selected', function( date )
        {
            document.getElementById( 'searchBarSelectedDate' ).value = date.format( 'YYYY-MM-DD' );
        } );

    }
}

function getDates()
{
    const dates = {};

    dates.today = new Date();
    dates.today = dates.today.toISOString().slice( 0, 10 );

    dates.tomorrow = new Date( dates.today );
    dates.tomorrow.setDate( dates.tomorrow.getDate() + 1 );
    dates.tomorrow = dates.tomorrow.toISOString().slice( 0, 10 );

    dates.twoYears = new Date( dates.today );
    dates.twoYears.setFullYear( dates.twoYears.getFullYear() + 2 );
    dates.twoYears = dates.twoYears.toISOString().slice( 0, 10 );

    return dates;
}

export function handleClearFilter()
{
    const clearFilter = document.getElementById( 'clear-all-filters-wrapper' );
    const sbdCookie = getCookie( 'dw_sbd' );
    const sbdCookieObj = sbdCookie ? JSON.parse( getCookie( 'dw_sbd' ) ) : null;
    if( sbdCookieObj )
    {
        if( sbdCookieObj?.countryAreas?.length > 0 || sbdCookieObj?.date !== null || sbdCookieObj?.duration !== null || ( sbdCookieObj && parseInt( sbdCookieObj.travelers ) !== 2 ) || sbdCookieObj?.priceType3 !== null || ( sbdCookieObj.destinationsType && sbdCookieObj.destinationsType.value !== 'all' ) )
        {
            clearFilter.classList.add( 'show-clear-filter' );
        }
    }

    const searchInputQuestions = document.querySelectorAll( '.search-input-question' );
    searchInputQuestions.forEach( function( inputQuestion )
    {
        const observer = new MutationObserver( function( mutations )
        {
            clearFilter.classList.add( 'show-clear-filter' );
        } );

        observer.observe( inputQuestion, {
            childList: true
        } );
    } );
}

export async function getTranslatedString( string )
{
    const response = await apos.http.post( '/getTranslatedString', { body: { string: string } } );
    if( response?.status === 'OK' )
    {
        return response.data;
    }

    return string;
}

function toggleMask()
{
    if( document.getElementById( 'page-mask' ).style.display === 'block' )
    {
        document.getElementById( 'page-mask' ).style.display = 'none';
    }
    else
    {
        document.getElementById( 'page-mask' ).style.display = 'block';
        document.getElementById( 'page-mask' ).style.zIndex = 37;
    }
}

export function handlePlusMinusElementClasses( inputEl, plusMinusContainer, excursionCard )
{
    if( parseInt( inputEl.value ) > parseInt( inputEl.min ) )
    {
        if( excursionCard )
        {
            excursionCard.classList.add( 'card-selected' );
        }

        plusMinusContainer.classList.remove( 'nr-disabled', 'minus-disabled' );
        plusMinusContainer.getElementsByClassName( 'decrease' )[0].style.pointerEvents = 'auto';
    }
    else
    {
        if( excursionCard )
        {
            excursionCard.classList.remove( 'card-selected' );
        }

        plusMinusContainer.classList.add( 'nr-disabled', 'minus-disabled' );
        plusMinusContainer.getElementsByClassName( 'decrease' )[0].style.pointerEvents = 'none';
    }

    if( parseInt( inputEl.value ) >= parseInt( inputEl.max ) )
    {
        plusMinusContainer.classList.add( 'plus-disabled' );
        plusMinusContainer.getElementsByClassName( 'increase' )[0].style.pointerEvents = 'none';
    }
    else
    {
        plusMinusContainer.classList.remove( 'plus-disabled' );
        plusMinusContainer.getElementsByClassName( 'increase' )[0].style.pointerEvents = 'auto';
    }
}
