import './splide.min.js';

export function initializeSliders()
{
    const sliders = document.querySelectorAll( '[data-splide-initialized="false"]' );
    if( sliders )
    {
        for( let i = 0; i < sliders.length; i++ )
        {
            if( sliders[i].classList.contains( 'image-slider' ) )
            {
                createImageSlider( sliders[i] );
            }
            else if( sliders[i].classList.contains( 'product-list-slider' ) )
            {
                createProductListSlider( sliders[i] );
            }
            else if( sliders[i].classList.contains( 'card-slider' ) )
            {
                createCardSlider( sliders[i] );
            }
            else if( sliders[i].classList.contains( 'top-or-similar-products-slider' ) )
            {
                createTopOrSimilarProductsSlider( sliders[i] );
            }
            else if( sliders[i].classList.contains( 'info-bullet-slider' ) )
            {
                createInfoBulletSlider( sliders[i] );
            }
        }
    }
}

function createImageSlider( slide )
{
    const splide = new window.Splide( slide, {
        rewind: true,
        arrows: true,
        lazyLoad: 'nearby'
    } );

    splide.on( 'pagination:mounted', function ( data )
    {
        if( window.innerWidth <= 768 )
        {
            data.items.forEach( function ( item )
            {
                item.button.style.display = 'none';
            } );
        }
        else
        {
            data.items.forEach( function ( item )
            {
                item.button.style.background = '#FEFBF399';
                item.button.style.border = '1px solid #FEFBF3';
                item.button.style.borderRadius = '2px';
            } );
        }
    } );

    splide.mount();

    const arrows = splide.Components.Arrows.arrows;
    const prev = arrows.prev;
    const next = arrows.next;

    prev.style.width = next.style.width = '40px';
    prev.style.height = next.style.height = '32px';
    prev.style.backgroundColor = next.style.backgroundColor = '#003B5A4D';
    prev.style.borderRadius = next.style.borderRadius = '15px';
    prev.style.opacity = next.style.opacity = '100%';

    slide.setAttribute( 'data-splide-initialized', 'true' );

    setTimeout( function()
    {
        const galleryButtonEl = document.querySelector( '.product-detail-gallery-btn' );
        if( galleryButtonEl )
        {
            galleryButtonEl.style.display = 'flex';
        }
    }, 1000 );

    // var splideLength = splide.Components.Controller.getEnd();
    //
    // splide.on( 'move', function(  )
    // {
    //     if( splideLength > 10 )
    //     {
    //         switch( splide.index ) {
    //             case 0:
    //             case 1:
    //                 for( var i = 0; i < paginations.length; i++ )
    //                 {
    //                     paginations[i].button.style.display = i < 5 ? 'block' : 'none';
    //                 }
    //                 break;
    //             case splideLength - 1:
    //             case splideLength:
    //                 for( var i = 0; i < paginations.length; i++ )
    //                 {
    //                     paginations[i].button.style.display = i > splideLength - 5 ? 'block' : 'none';
    //                 }
    //                 break;
    //             default:
    //                 for( var i = 0; i < paginations.length; i++ )
    //                 {
    //                     paginations[i].button.style.display = i === splide.index || i === splide.index - 2 || i === splide.index - 1 || i === splide.index + 1 || i === splide.index + 2 ? 'block' : 'none';
    //                 }
    //                 break;
    //         }
    //     }
    // });
}

function createProductListSlider( slide )
{
    const splide = new window.Splide( slide, {
        rewind: true,
        arrows: true,
        lazyLoad: 'nearby'
    } );

    splide.mount();

    const arrows = splide.Components.Arrows.arrows;
    const prev = arrows.prev;
    const next = arrows.next;

    if( window.innerWidth <= 768 )
    {
        prev.style.display = next.style.display = 'none';
    }
    else
    {
        prev.style.width = next.style.width = '40px';
        prev.style.height = next.style.height = '32px';
        prev.style.backgroundColor = next.style.backgroundColor = '#003B5A4D';
        prev.style.borderRadius = next.style.borderRadius = '15px';
        prev.style.opacity = next.style.opacity = '100%';
    }

    const paginations = splide.Components.Pagination.items;
    for( let i = 0; i < paginations.length; i++ )
    {
        paginations[i].button.style.background = '#FEFBF399';
        paginations[i].button.style.border = '1px solid #FEFBF3';
        paginations[i].button.style.borderRadius = '2px';
    }

    slide.setAttribute( 'data-splide-initialized', 'true' );
}

function createCardSlider( slide )
{
    const splide = new window.Splide( slide, {
        trimSpace: false,
        focus: '0',
        arrows: true,
        pagination: false,
        fixedWidth: '384px',
        breakpoints: {
            1200: {
                fixedWidth: '277px'
            }
        }
    } );

    splide.mount();

    const sliderProgressBar = splide.root.querySelector( '.card-slider-progress-bar' );

    splide.on( 'mounted move', function ()
    {
        sliderProgressBar.style.width = String( 100 * ( splide.index + 1 ) / ( splide.Components.Controller.getEnd() + 1 ) ) + '%';
    } );

    const end = splide.Components.Controller.getEnd() + 1;
    const arrows = splide.Components.Arrows.arrows;
    const prev = arrows.prev;
    const next = arrows.next;

    prev.style.position = next.style.position = 'initial';
    prev.style.width = next.style.width = '72px';
    prev.style.height = next.style.height = '56px';
    prev.style.backgroundColor = next.style.backgroundColor = '#fefbf3';
    prev.style.border = next.style.border = '2px solid #006C86';
    prev.style.borderRadius = next.style.borderRadius = '75px';

    if( window.innerWidth < 768 )
    {
        prev.style.width = next.style.width = '60px';
        prev.style.height = next.style.height = '44px';
    }

    const sliderProgressContainer = splide.root.querySelector( '.card-slider-progress' );
    const sliderBarPosition = sliderProgressContainer.scrollWidth / end;
    sliderProgressContainer.addEventListener( 'click', function( event )
    {
        splide.go( Math.floor( event.layerX / sliderBarPosition ) );
    } );

    slide.setAttribute( 'data-splide-initialized', 'true' );
}

function createTopOrSimilarProductsSlider( slide )
{
    const splide = new window.Splide( slide, {
        trimSpace: false,
        focus: '0',
        arrows: true,
        pagination: false,
        fixedWidth: '904px',
        breakpoints: {
            1200: {
                fixedWidth: '700px'
            },
            767: {
                fixedWidth: '277px'
            }
        }
    } );

    splide.mount();

    const sliderProgressBar = splide.root.querySelector( '.card-slider-progress-bar' );

    splide.on( 'mounted move', function ()
    {
        sliderProgressBar.style.width = String( 100 * ( splide.index + 1 ) / ( splide.Components.Controller.getEnd() + 1 ) ) + '%';
    } );

    const end = splide.Components.Controller.getEnd() + 1;
    const arrows = splide.Components.Arrows.arrows;
    const prev = arrows.prev;
    const next = arrows.next;

    prev.style.position = next.style.position = 'initial';
    prev.style.width = next.style.width = '72px';
    prev.style.height = next.style.height = '56px';
    prev.style.backgroundColor = next.style.backgroundColor = '#fefbf3';
    prev.style.border = next.style.border = '2px solid #006C86';
    prev.style.borderRadius = next.style.borderRadius = '75px';

    if( window.innerWidth < 768 )
    {
        prev.style.width = next.style.width = '60px';
        prev.style.height = next.style.height = '44px';
    }

    const sliderProgressContainer = splide.root.querySelector( '.card-slider-progress' );
    const sliderBarPosition = sliderProgressContainer.scrollWidth / end;
    sliderProgressContainer.addEventListener( 'click', function( event )
    {
        splide.go( Math.floor( event.layerX / sliderBarPosition ) );
    } );

    slide.setAttribute( 'data-splide-initialized', 'true' );
}

function createInfoBulletSlider( slide )
{
    const splide = new window.Splide( slide, {
        arrows: false,
        pauseOnHover: false,
        pagination: false,
        autoplay: true,
        rewind: true
    } );

    splide.mount();
    slide.setAttribute( 'data-splide-initialized', 'true' );
}

export function initializeFullScreenSlider( id )
{
    const splide = new window.Splide( '#fullScreenSlider-' + id, {
        width: '100vw',
        height: '100vh',
        rewind: true,
        arrows: true,
        lazyLoad: 'nearby'
    } );

    splide.on( 'pagination:mounted', function ( data )
    {
        data.items.forEach( function ( item )
        {
            item.button.style.background = '#FEFBF399';
            item.button.style.border = '1px solid #FEFBF3';
            item.button.style.borderRadius = '2px';
        } );
    } );

    splide.mount();

    const masonryContainer = document.getElementById( 'product-detail-masonry-' + id );
    masonryContainer.addEventListener( 'click', function( event )
    {
        if( event.target.dataset.index )
        {
            splide.go( event.target.dataset.index - 1 );
        }
    } );
}
